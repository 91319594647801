.Percent,
.Percent2 {
    width: 70px;
    height: 23px;
    border: none;
    border-radius: 5px;
    transform: translateY(-1.6rem) translateX(2.9rem);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.reminder {
    display: flex;
    justify-content: center;
}

.Percent:focus,
.Percent2:focus {
    outline: none;
}

/* Positive and negative styles */
.Positive {
    background-color: rgba(0, 188, 75, 0.15);
    /* Light green background */
    color: var(--green);
    /* Green text color */
    align-items: center;
    justify-content: center;
    text-align: center;
    transform: translateY(-0.5rem) translateX(3rem);
}

.Negative {
    background-color: var(--darkred);
    /* Dark red background */
    color: var(--red);
    /* Red text color */
    transform: translateY(-0.5rem) translateX(3rem);
}

/* Add triangle indicators outside the button */
.Positive::after,
.Negative::after {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 0;
    height: 0;
    left: -15px;
    /* Adjust this value to position the triangle */
}

.Positive::after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid var(--green);
}

.Negative::after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid var(--red);
}

/* Adjust Percent2 specific transform */
.Percent2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 25px;
    -webkit-transform: translateY(-1.6rem) translateX(2.9rem);
    -ms-transform: translateY(-1.6rem) translateX(2.9rem);
    transform: translateY(-1.6rem) translateX(2.9rem);
}

/* Additional styles as provided */
.ChartUnion-Container {
    width: 100%;
    max-width: 80rem;
    height: fit-content;
    background-color: var(--white);
    margin: 5rem auto;
    border-radius: 15px;
    transform: translateY(-1rem) translateX(-1.7rem);
    padding: 1.5rem;
}

.ChartUnion-Context {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    /* Responsive grid layout */
    margin-left: 2rem;
    justify-content: space-between;
    column-gap: 13rem;
}

.ChartUnion-Context>*:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}

.ChartUnion-Context>*:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
}

.ChartUnion-Context>*:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 5;
}

.FinancialTitle {
    margin-left: 2rem;
    -webkit-transform: translateY(1rem);
    -ms-transform: translateY(1rem);
    transform: translateY(0rem) translateX(4px);

}

.TitleFinac {
    cursor: copy;
    color: var(--black);
    color: var (--red);
    font-weight: 600;
    width: 280px;
}

.Seemore {
    width: 120px;
    height: 16px;
    color: var(--blue);
    cursor: pointer;
    margin-left: 20rem;
    -webkit-transform: translateY(-2rem);
    -ms-transform: translateY(-2rem);
    transform: translateY(-3.2rem);
}

/* პირველი ჩარტი პირველი ჩარტ კომპონენტისგან */
.FirstChart {
    width: 24rem;
    height: 25rem;
    grid-column: 1/1;
    grid-row: 1;
    border-radius: 15px;
}

/* მეორე ჩარტი მეორე ჩარტ კომპონენტისგან */
.SecondChart {
    width: 24rem;
    height: 25rem;
    border-radius: 15px;
}

.HalfItems {
    display: -ms-grid;
    display: grid;
    gap: 3rem;
    margin-left: 2rem;
}

.SmallChart {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 210px;
    height: fit-content;
    border-radius: 15px;
    border: 1.5px solid #D9DBE9;
    outline: none;
    background-color: var(--white);
}

.SkeletonLoader {
    width: 210px;
    /* Width of the rectangle */
    height: 110px;
    /* background: linear-gradient(-90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%); */
    background: linear-gradient(-90deg, #f5f5f5 25%, #ffffff 50%, #f5f5f5 75%);

    border: 1.5px solid #D9DBE9;
    /* height:fit-content; */

    background-size: 400% 100%;
    animation: skeleton-loading 2.2s ease-in-out infinite;
    border-radius: 4px;
    /* Rounded corners */
}

/* Skeleton loader animation */
@keyframes skeleton-loading {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}


/* ტექსტები ზევით ასაწევი */
.FirstItem-Title {
    margin-left: 0.2rem;
    width: 170px;
}

/* First Chart */
.FirstItem-Title .Title {
    color: var(--black);
    font-weight: 600;
    cursor: copy;
    transform: translateY(1rem) translateX(-0.6rem);
    width: 190px;
    text-align: center;
}

/* Second chart */
.FirstItem-Title .Title2 {
    display: flex;
    justify-content: center;
    color: var(--black);
    font-weight: 600;
    cursor: copy;
    align-items: center;
    transform: translateY(-0.3rem) translateX(-0.7rem);
    width: 188px;
}

/* Number */
.FirstItem-Title .Number {
    color: var (--blue);
    font-weight: bold;
    width: 130px;
    margin-left: 1.5rem;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.FirstItem-Title .Number2 {
    color: var (--blue);
    font-weight: bold;
    width: 130px;
    margin-left: 1.4rem;
    transform: translateY(-1.2rem);
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* რეკლამის ბათონის გაკეთება ჩვენს ფინანსურ ანგარიშგებაზე */
.PoweredBy {
    position: absolute;
    right: 2rem;
    transform: translateY(-7.33rem);
    display: flex;
}

.PoweredBy:hover {
    cursor: copy;
}

.Advertisement {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12rem;
    height: 2.2rem;
    background-color: var(--blue);
    color: white;
    border-radius: 15px 15px 0px 0px;
    font-size: 15px;
}

/* ახალი კონტეინერი ფინანსური ანგარიშგება */
.FinanceStatement-Container {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 100%;
    max-width: 80.7rem;
    height: fit-content;
    border-radius: 15px;
    background-color: var(--white);
    transform: translateX(-1.5rem);
}

.FinanceStatement-Context {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-auto-rows: minmax(45px, auto);
    margin-left: 2rem;
    align-items: center;
    padding: 2rem;
    /* background-color:black; */
}

.Finance-Title {
    color: var(--black);
    font-weight: 600;
    width: 100%;
    max-width: 240px;
    height: 16px;
    cursor: copy;
    margin-top: -1.2rem;
}

.Finance-Info {
    color: #9298A6;
    font-weight: 500;
    margin-top: 10px;
    width: 100%;
    max-width: 650px;
    margin-top: -2rem;
}

.Finance-Button {
    display: flex;
    gap: 1.5rem;
    transform: translateY(-1.3rem);
}

.Finance-Button:focus {
    outline: none;
}

.Finance-Cond {
    width: 100%;
    max-width: 240px;
    height: 35px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    background-color: #F7F9FC;
    color: var(--blue);
    font-size: 14px;
    font-weight: lighter;
}

.Finance-Cond:focus {
    outline: none;
}

.Finance-Way {
    width: 100%;
    max-width: 300px;
    height: fit-content;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    background-color: #F7F9FC;
    color: var(--blue);
    font-size: 14px;
    font-weight: lighter;
}

/* Custom css for 2 components */
.mainPageWrapperSecond {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 4.9rem;
    -webkit-transform: translateY(0.5rem) translateX(-1.5rem);
    -ms-transform: translateY(0.5rem) translateX(-1.5rem);
    transform: translateY(0.5rem) translateX(-1.5rem);
}

@media (max-width:1240px) {
    .ChartUnion-Context {
        display: grid;
        column-gap: 2rem;
        row-gap: 3rem;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }


}

@media (max-width:1000px) {
    .ChartUnion-Context {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .FinancialTitle {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Seemore {
        position: relative;
        margin-top: -2rem;
    }

    .ChartUnion-Container {
        width: 100%;
        max-width: 50rem;
    }
}


@media (max-width:905px) {
    .mainPageWrapperSecond {
        display: flex;
        flex-direction: column;
    }
}


@media(min-width:300px) {
    .SmallChart .FirstItem-Title {
        transform: translateY(-1rem) translateX(-0.5rem);
        height: fit-content;
    }

    .FirstItem-Title .Title2 {
        transform: translateY(1rem);
    }

    .FirstItem-Title .Number2 {
        transform: translateY(0.5rem);
    }

    .FirstItem-Title .Percent2 {
        transform: translateY(0rem) translateX(3.5rem);
    }
}



@media (max-width:514px) {
    .Finance-Cond {
        height: fit-content;
    }

    .Finance-Way {
        height: fit-content;
    }
}

@media(max-width:800px) {
    .PoweredBy {
        transform: translateY(-7.4rem);
    }
}

@media (max-width:505px) {
    .Finance-Button {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
    }

    .Finance-Info {
        padding: 0.8rem;
    }

    .Finance-Title {
        padding: 0.5rem;
        transform: translateY(-5px) translateX(4px);
    }

    .Finance-Cond {
        height: 2rem;
    }

    .Finance-Way {
        height: 2rem;
    }

    .PoweredBy {
        transform: translateY(-11rem);
    }

}





@media (max-width:498px) {
    .ChartUnion-Context {
        max-width: 10rem;
        transform: translateX(7rem);
    }
}


@media (max-width:486px) {
    .PoweredBy {
        max-width: 14rem;
    }
}

@media(max-width:447px) {
    .PoweredBy {
        transform: translateY(-11.5rem);
    }
}

/* small screen size */

@media (max-width:455px) {
    .ChartUnion-Context {
        transform: translateX(6.3rem);
    }
}

@media (max-width:446px) {
    .Finance-Info {
        width: 100%;
        max-width: 320px;
    }

    .ChartUnion-Container {
        width: 28rem;
    }
}

@media (max-width:407px) {
    .Finance-Button {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
        width: 100%;
        max-width: 20rem;
    }

    .Finance-Info {
        padding: 0.8rem;
        width: 100%;
        max-width: 320px;
    }

    .PoweredBy {
        width: 100%;
        max-width: 15rem;
    }

}

@media(max-width:370px) {
    .Finance-Info {
        width: 100%;
        max-width: 300px;
    }

    .Finance-Button {
        width: fit-content;
    }
}