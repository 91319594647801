.chart-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width:90%;
  max-width:113rem;
  margin: 0 auto;
  background-color:var(--white);
          height:fit-content;
    border-radius:15px;
    height:fit-content;
    padding:3rem;
}

.chart-container  h2 {
  transform:translateY(-2rem);
}
.chart-container  h4{
  transform:translateY(-3rem);
}

.charts-row {
  display:grid;
  justify-content:center;
  grid-template-columns:repeat(auto-fit, minmax(430px, 1fr));
  width:100%; 
  row-gap:2rem;
  transform:translateY(-2rem) translateX(2rem);



  /* different browser */
  -webkit-box-pack:center;
  -ms-flex-pack:center;
}

.chart-title {
  text-align: center;
  width: 100%;
}

.chart-title1 {
  text-align: center;
  font-size: 14px;
  margin-bottom: 0;
  color:var(--yellow);
}

.chart-title2{
  text-align: center;
  font-size: 14px;
  margin-bottom: 0;
  color: var(--blue);
}

.bar-chart-wrapper {
  width: 450px;
  background-color: #f1f6ff;
  border-radius: 5%;
  height: 26rem;
}
.bar-chart-wrapper2{
  width: 450px;
  background-color: #f1f6ff;
  border-radius: 5%;
  height: 26rem;
}

h1 {
  text-align: center;
  width: 100%;
}

h4{
  color: silver;
  margin-right:2.5rem;
}

.Chartloader {
  border: 8px solid #f3f3f3; 
  border-top: 8px solid var(--blue); 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite; 
  margin: auto;
  margin-top: 8rem;
}

.DoubleChartloader{
  border: 8px solid #f3f3f3; 
  border-top: 8px solid var(--blue); 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite; 
  margin: auto;
  margin-top: 6rem;
}

/* Responsive web design */

/* Desktop device 1000px 1700px */
@media (max-width:1629px){
  .chart-container{
    width:100%;
    max-width:70rem;
  }
}
/* For tablet 1k - 550px */
@media (max-width:995px){
  .chart-container{
    width:100%;
    max-width:50rem;
  }
  .charts-row{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  }
}

/* phoe device  */
@media (max-width:501px){
    .bar-chart-wrapper{
      width:400px;
    }
    .bar-chart-wrapper2{
      width:432px;
    }
}

@media (max-width:455px){
  .bar-chart-wrapper2{
    transform:translateX(-0.5rem);
  }
}
