.MainContainer-landingpage {
    width: 100%;
    max-width: 89rem;
    background-color: white;
    border-radius: 15px;
    margin: 8rem auto;
    padding: 1rem;
}

.AllContent-landingpage {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    padding: 1rem;
    transform: translateX(0.3rem) translateY(1.3rem);
    row-gap: 2rem;
}


/* Company title */
.CompanyNews {
    display: flex;
    justify-content: flex-start;
    transform: translateY(-1.5rem) translateX(5.3rem);
}

.CompanyNews .Title {
    color: rgba(101, 106, 249, 1);
    font-weight: 600;
}

.CompanyNews .PartTitle {
    transform: translateY(1.3rem) translateX(-2rem);
    color: #000;
    font-weight: 600;
}


.MainNews {
    width: 100%;
    max-width: 15rem;
    height: 17rem;
    background-color: rgba(247, 249, 252, 1);
    margin-left: 3.6rem;
    border-radius: 0px 0px 10px 10px;
    cursor: pointer;
    border-radius: 15px;
    transform: translateY(-1.5rem) translateX(-2rem);
}

/* Main News Title */

.MainNews .NewsTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 1);
    width: 100%;
    max-width: 14rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 600;
}


.MainNews .Full {
    text-align: end;
    font-weight: 500;
    color: rgba(101, 106, 249, 1);
    margin-right: 1rem;
    transform: translateY(-2.1rem);
}

.MainNews .fonticon {
    position: relative;
    margin-left: 0.5rem;
}

.ContainerImage {
    width: 16rem;
    max-width: 100%;
    height: 8.2rem;
    border-radius: 15px 15px 0px 0px;
    z-index: 1;
}


.btn {
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-column: 4;
    grid-column: 4;
    color: rgba(101, 106, 249, 1);
    margin-left: 9.3rem;
    -webkit-transform: translateY(1rem);
    -ms-transform: translateY(1rem);
    transform: translateY(1rem);
}

.Media-landingpage {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 6rem;
    max-width: 8rem;
    margin-left: 1.13rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    margin-top: 1.5rem;
    transform: translateX(-1.4rem);
}



@media (max-width:845px) {
    .MainContainer-landingpage {
        width: 100%;
        max-width: 45rem;
    }
}

/* Mobile phone responsive */
@media (max-width:579px) {
    .MainContainer-landingpage {
        width: 100%;
        max-width: 30rem;
    }

    .AllContent-landingpage {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 2.5rem;
    }

    .CompanyNews {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(3.5rem) translateY(-0.5rem);
    }
}