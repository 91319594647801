.table-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 15vw;
  font-size: 13px;
  margin-top: 2rem;
  margin-bottom: 3rem;
  width: 70vw;
  border-radius: 30px;
  border: none;
}

.tableWrapper {
  margin-bottom: 2rem;
  position: relative;
  width: 100%; /* Ensures the wrapper takes full width */
}

.powered-by {
  background-color: #656AF9;
  color: white;
  padding: 5px 10px; /* Adjust padding for resizing */
  border-radius: 15px 15px 0px 0px;
  font-size: 15px; /* Adjust font size for resizing */
  font-weight: bold;
  position: absolute;
  top: -2.5rem; /* Adjust top position as needed */
  right: 0; /* Align to the right end */
  z-index: 1;
  width: 200px; /* Set a fixed width */
  text-align: center; 
  -webkit-transform:translateY(3.2rem); 
      -ms-transform:translateY(3.2rem); 
          transform:translateY(3.2rem); /* Center the element horizontally */
  height: 35px;
}

.tableClass {
  border-collapse: collapse;
  border: 1px solid #ececec;
  margin-top: 3rem;
  background-color: var(--white);
  border-radius: 20px;
  border: none;
  width: 100%; /* Ensure the table takes full width of the container */
}

.tableClass th,
.tableClass td {
  padding: 8px;
  text-align: center; /* Center-align the numbers in the columns */
  border-left: none;
  border-right: 1px solid #dfdfdf;
}

.tableClass td:first-child,
.tableClass th:first-child {
  text-align: left; /* Align the text in the first column to the left */
  padding-left: 25px; /* Add padding to move the text to the right */
  width: 60%;
}

.tableClass th:first-child {
  color: silver;
}

.tableClass td:last-child,
.tableClass th:last-child {
  border-right: none;
}

.tableClass tbody tr:not(:last-child) td {
  border-bottom: 1px solid #dfdfdf;
}

.tableClass th {
  background-color: #ffffff;
}

.tableRowStyle {
  background-color: rgba(101, 106, 249, 0.1);
  color: var(--blue);
}

.tableSelect {
  position: relative;
  right: 29vw;
  width: 150px;
  padding: 7px;
  border: 1px solid silver;
  border-radius: 10px;
  background-color: var(--white);
  font-size: 12px;
  color: var(--darkgray);
  cursor: pointer;
  font-family: 'AvenirNextGeorgianRegular';
}

.tableSelect:focus {
  border-color: silver;
  outline: none;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid var(--blue);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  margin: auto;
  margin-top: 50px;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.highlighted-row {
  background-color: rgba(101, 106, 249, 0.1); /* Light purple background with low opacity */
  color: #656AF9; /* Purple font color */
}

.highlighted-obligation-row {
  background-color: rgba(101, 106, 249, 0.1); /* Light purple background with low opacity */
  color: #656AF9;
}

.highlighted-capital-row{
  background-color: rgba(101, 106, 249, 0.1); /* Light purple background with low opacity */
  color: #656AF9;
}

.highlighted-total-capital-row {
  background-color: rgba(101, 106, 249, 0.1); /* Light purple background with low opacity */
  color: #656AF9;
}

.highlighted-capital-and-obligation-row {
  background-color: rgba(101, 106, 249, 0.1); /* Light purple background with low opacity */
  color: #656AF9;
}