.Main-Container-EasySearch {
    width: 100%;
    margin: 3rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AllItems-SearchItems {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 3));
    grid-template-rows: repeat(3, 118px);
    color: black;
    background-color: white;
    width: 67%;
    border-radius: 15px;
    height: fit-content;
}

.search-icon {
    transform: translateX(36px);
}

.CompanyContainer-StartingPage {
    display: flex;
    justify-content: center;
    /* Align items to the start */
    align-items: center;
    /* Align items vertically */
    gap: 1.5rem;
    height: fit-content;
    border-radius: 15px;
    overflow-x: auto;
    /* Ensure horizontal scrolling */
    max-width: 100%;
    box-sizing: border-box;
    /* Include padding and border in the element's total width and height */
    white-space: nowrap;
    /* Prevent the text from wrapping */
    transform: translateY(-2rem);
}

.FinancialInformation {
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
}

.SearchBar {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-2.8rem);
}

.search {
    width: 100%;
    max-width: 39rem;
    height: 4rem;
    border: 3px solid rgba(101, 106, 249, 1);
    border-radius: 15px;
    text-indent: 3rem;
}

.SearchBar .search:focus {
    outline: none;
}



.Company1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.5rem;
    height: 2.5rem;
    border-radius: 15px;
    background-color: #F7F9FC;
    flex-shrink: 0;
    /* Prevent shrinking */
    white-space: nowrap;
    /* Prevent the text from wrapping */
    /* background-color:blue; */
    cursor: pointer;
}

.Company1 img {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    margin-left: -0.5rem;
    margin-top: 5px;
}

.Company1 a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    margin-left: 0.5rem;
}

.background {
    min-height: 100vh;
    overflow: auto;
}

.mainPageWrapper {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3.8rem;
    transform: translateY(1rem);
}

.suggestions-dropdown {
    position: absolute;
    background-color: #fff;
    border: 1px solid rgba(101, 106, 249, 1);
    border-radius: 8px;
    width: 35rem;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
}

.suggestions-dropdown li {
    padding: 10px 20px;
    list-style: none;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
}

.suggestions-dropdown li:hover {
    background-color: #f9f9f9;
}

.suggestions-dropdown li:last-child {
    border-bottom: none;
}

/* Computer */

@media(max-width:1400px) and (min-width:800px) {
    .FinancialInformation {
        max-width: 60rem;
        font-size: 22px;
        transform: translateY(-0.2rem);
    }
}

@media(max-width:1200px) {
    .FinancialInformation {
        transform: translateX(4rem) translateY(-0.5rem);
    }
}

@media (max-width:1070px) {
    .FinancialInformation {
        max-width: 35rem;
    }
}

@media (max-width:966px) {
    .mainPageWrapper {
        display: flex;
        flex-direction: column;
    }

    .FinancialInformation {
        transform: translateX(1rem) translateY(-0.5rem);
    }
}


@media(max-width:800px) {
    .FinancialInformation {
        transform: translateY(0rem) translateX(1rem);
        font-size: 19px;
    }
}




@media (max-width: 756px) {
    .FinancialInformation {
        max-width: 25rem;
        font-size: 20px;
        transform: translateX(1.5rem) translateY(0rem);
    }

    .Title {
        transform: translateY(0rem) translateX(2.8rem);
    }

    .SearchBar {
        max-width: 25rem;
        transform: translateX(3rem) translateY(-2.5rem);
    }
}



@media (max-width:700px) {
    .Title {
        transform: translateX(3.3rem) translateY(0.6rem);
    }

    .FinancialInformation {
        font-size: 16px;
        max-width: 20rem;
    }

    .SearchBar {
        max-width: 22rem;
        transform: translateX(3rem) translateY(-2.6rem);
    }

    .search {
        font-size: 11px;
    }

    .CompanyContainer-StartingPage {
        transform: translateY(-2rem);
    }
}


@media (max-width: 620px) {
    .SearchBar {
        max-width: 20rem;
    }

    .search {
        font-size: 15px;
    }

    .FinancialInformation {
        transform: translateX(0.5rem);
    }
}


@media (max-width: 560px) {
    .FinancialInformation {
        max-width: 20rem;
        transform: translateX(0.5rem);
    }

    .Title {
        transform: translateX(1.5rem) translateY(0.5rem);
    }

    .SearchBar {
        transform: translateX(1rem) translateY(-2.5rem);
    }

    .CompanyContainer-StartingPage {
        transform: translateX(-0.5rem) translateY(-2rem);
    }
}



@media (max-width: 510px) {
    .FinancialInformation {
        max-width: 20rem;
        font-size: 15px;
        transform: translateX(0.5rem) translateY(-0.4rem);
    }

    .SearchBar {
        max-width: 17rem;
        transform: translateY(-3.3rem) translateX(1.1rem);
    }

    .CompanyContainer-StartingPage {
        transform: translateY(-3rem);
    }
}

@media (min-width: 490px) {
    .CompanyContainer-StartingPage {
        flex-wrap: wrap;
    }
}

@media (min-width: 470px) {
    .CompanyContainer-StartingPage {
        flex-wrap: wrap;
    }
}

@media (max-width: 470px) {
    .CompanyContainer-StartingPage {
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        gap: 1rem;
        max-width: 100%;
        overflow-x: auto;
        box-sizing: border-box;
        white-space: nowrap;
    }
}






@media (max-width:471px) {
    .FinancialInformation {
        font-size: 14px;
        max-width: 18rem;
        transform: translateX(0.2rem) translateY(0.1rem);
    }

    .SearchBar {
        transform: translateX(1.3rem) translateY(-2.8rem);
        max-width: 15rem;
    }
}

@media(max-width:445px) {
    .AllItems-SearchItems {
        max-width: 100%;
        width: 21rem;
    }

    .Title {
        transform: translateX(2.1rem);
        font-size: 25px;
    }

    .FinancialInformation {
        font-size: 15px;
    }

    .SearchBar {
        margin-left: 0.8rem;
        max-width: 16.5rem;
    }
}

/* extra small phone  */


@media(max-width:406px) {
    .FinancialInformation {
        transform: translateX(0.2rem);
    }

    .SearchBar {
        transform: translateX(1.5rem) translateY(-3rem);
    }
}

@media(max-width:390px) {
    .FinancialInformation {
        transform: translateX(1rem) translateY(0.4rem);
        max-width: 17rem;
        font-size: 14px;
    }

    .SearchBar {
        transform: translateX(2.3rem) translateY(-3rem);
        max-width: 15rem;
    }
}



@media(max-width:340px) {
    .FinancialInformation {
        transform: translateX(0.5rem) translateY(0.5rem);
    }

    .SearchBar {
        transform: translateX(2rem) translateY(-2.7rem);
    }
}