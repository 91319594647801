.my-component-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.my-component-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Keep as flex-start to not affect internal content alignment */
    background-color: #F7F9FC;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10000;
    /* Ensure it's above the overlay */
    overflow: visible;
    padding: 20px;
    width: 100%;
    max-width: 80rem;
    height: 52rem;
    transform: translateY(2.5rem);
    /* Keep the translateY for vertical adjustment */
}

.my-component-close-button {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-4rem) translateY(4rem);
    cursor: pointer;
}

.my-component-sidebar {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 200px);
    width: 23rem;
    background-color: white;
}

.my-component-sidebar button {
    width: 224px;
    height: 50px;
    border: none;
    outline: none;
    border-radius: 15px;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    color: #656AF9;
    /* background-color: rgba(101, 106, 249, 0.1); */
    transition: background-color 0.2s ease, color 0.5s ease;
}

.my-component-sidebar button.active,
.my-component-sidebar button:hover {
    /* background-color: rgba(101, 106, 249, 0.1); */
    color: #656AF9;
}

.my-component-header {
    display: flex;
    justify-content: center;
    transform: translateY(1rem);
}



.my-component-favorite-companies {
    display: flex;
    flex-wrap: wrap;
    transform: translateY(2rem);
    column-gap: 2rem;
    row-gap: 3rem;
    overflow-x: hidden;
    max-height: 35rem;
}


.Savingcompany {
    transform: translateY(-1rem) translateX(1.4rem);
    max-height: 4rem;
}

.my-component-company-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 104px;
    background-color: #FFFFFF;
    border-radius: 10px;
    width: 100%;
    max-width: 15rem;
    transform: translateX(1.4rem);
}

.my-component-company-card div {
    flex-grow: 1;
}

.SavingCompany-2 {
    position: absolute;
    left: 33%;
    top: 18%;
}

.SomeWidth {
    width: 18rem;
    height: 10rem;
    background-color: green;
}

.SavingCompanyIndustry {
    transform: translateY(-3.3rem) translateX(4.2rem);
}

.SavingCompanyIndustry button {
    background-color: white;
    font-size: 14px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    width: 15rem;
    transition: background-color 0.2s ease, color 0.5s ease;
}

.SavingCompanyIndustry button.active,
.SavingCompanyIndustry button:hover {
    background-color: rgba(101, 106, 249, 0.1);
}

.SavingCompanyIndustry .HeartIcon {
    transform: translateX(-0.5rem) translateY(2.5px);
}

.ChangingNum {
    transform: translateY(2rem);
}

.ChangingNum button {
    background-color: white;
    color: #000;
    font-size: 18px;
    transition: background-color 0.2s ease, color 0.5s ease;
}



.ChangingNum .TelephoneIcon {
    transform: translateX(-0.7rem) translateY(2px);
}

.Leave-Page {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transform: translateY(-6.5rem);
    grid-row: 4;
}

.Leave-Page button {
    width: 10rem;
    height: 44px;
    background-color: rgb(245, 203, 209);
    color: #BC0022;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    transition: background-color 0.2s ease, color 0.5s ease;
}

.Leave-Page button:hover {
    background-color: rgb(255, 173, 179);
    color: #90001b;
}

.left-arrow-icon {
    transform: translateX(-5px) translateY(5px);
}


.ArrowIcon {
    transform: translateY(-1px);
}

.seemorinio {
    display: flex;
    transform: translateX(10.5rem) translateY(-21px);
    cursor: pointer;
    color: gray;
    font-weight: bold;
}

.CompanyTitle2 {
    margin: 0;
    /* Reset default margin */
    font-size: 14px;
    /* Adjust font size */
    white-space: wrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Hide overflow text */
    text-overflow: ellipsis;
    /* Add ellipsis to overflow text */
    transform: translateX(-2rem);
    width: 10rem;
}



.my-component-delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 4rem;
    max-width: 6rem;
    height: 1.5rem;
    background-color: #FFFFFF;
    color: #D80027;
    border: none;
    outline: none;
    border-radius: 15px;
    cursor: pointer;
    margin-left: auto;
    transform: translateY(-2.7rem) translateX(-2.2rem);
    z-index: 1000;
}

.active-button {
    background-color: rgba(101, 106, 249, 0.1);
    font-size: 16px;
    color: #656AF9;
    font-weight: bold;
    cursor: pointer;
    width: 15rem;
}

.SavingCompanyIndustry .HeartIcon,
.ChangingNum .TelephoneIcon {
    transform: translateX(-0.5rem) translateY(2.5px);
}

.my-component-contact-numbers {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 250px);
    background-color: #FFFFFF;
    width: 26rem;
    height: 35rem;
    border-radius: 1rem;
    transform: translateX(12.5rem) translateY(-1rem);
}

.ChangingNumberText {
    display: flex;
    justify-content: center;
    transform: translateY(-3rem);
    font-size: 18px;
}

.my-component-number-group {
    grid-row: 1;
    transform: translateY(5rem);
}

.my-component-number-group input {
    border: none;
    outline: none;
    transform: translateX(3rem) translateY(-4.4rem);
    width: 19rem;
    height: 40px;
    margin: 10px;
    border-radius: 15px;
    text-indent: 4.5rem;
    background-color: #F7F9FC;
    border: 1px solid darkgray;
    /* ეს გამოვწიეთ რადგან ჩაისმება საქართველოს დროშა თავიდან ;0 */
}


.SendMessage {
    transform: translateY(5rem);
}

.flag-text {
    background-color: red;
    width: 4rem;
    transform: translateX(3rem);
}


.existing-number {
    display: flex;
    justify-content: center;
    transform: translateY(-1rem) translateX(5px);
    color: #9298A6;
    font-weight: 600;
    font-size: 16px;
}


.ChangingNumberText {
    display: flex;
    justify-content: center;
    transform: translateY(-3rem);
    font-size: 18px;
    letter-spacing: 0.3px;
}

.my-component-number-group {
    grid-row: 1;
    transform: translateY(5rem);
}

.my-component-number-group input {
    border: none;
    outline: none;
    transform: translateX(3rem) translateY(-2.5rem);
    width: 19rem;
    height: 40px;
    margin: 10px;
    border-radius: 15px;
    text-indent: 4.5rem;
    background-color: #F7F9FC;
    border: 1px solid rgba(247, 249, 252, 1);
}

.my-component-number-group .SmsCode-Input {
    text-indent: 1rem;
}

.my-component-number-group ::placeholder {
    font-family: Arial, Helvetica, sans-serif;
    color: #9298A6;
}

.SendMessage {
    width: 113px;
    height: 36px;
    color: rgba(101, 106, 249, 1);
    background-color: rgba(101, 106, 249, 0.15);
    border: none;
    border-radius: 15px;
    transform: translateY(-5.6rem) translateX(15.7rem);
    cursor: pointer;
    font-size: 12px;
}

.SendMessage:focus {
    outline: none;
}

/* მიუთითეთ არსებული ნომერი */
.existing-number {
    display: flex;
    justify-content: center;
    transform: translateY(-1.5rem) translateX(5px);
    color: #9298A6;
    font-weight: 600;
    font-size: 16px;
}


.existing-NewNumber {
    display: flex;
    justify-content: center;
    font-weight: 600;
    color: rgba(146, 152, 166, 1);
    transform: translateY(1.5rem);
}



/* Second input */
.my-component-number-group-second input {
    border: none;
    outline: none;
    transform: translateX(3rem) translateY(1rem);
    width: 19rem;
    height: 40px;
    margin: 10px;
    border-radius: 15px;
    text-indent: 4.5rem;
    background-color: #F7F9FC;
    border: 1px solid rgba(247, 249, 252, 1);
}

.my-component-number-group-second .SmsCode-Input2 {
    text-indent: 1rem;
    font-family: Arial, Helvetica, sans-serif;
}

.my-component-number-group-second ::placeholder {
    font-family: Arial, Helvetica, sans-serif;
    color: #9298A6;
}

.Code-Receive {
    width: 113px;
    height: 36px;
    color: rgba(101, 106, 249, 1);
    background-color: rgba(101, 106, 249, 0.15);
    border: none;
    border-radius: 15px;
    transform: translateY(-2.1rem) translateX(15.7rem);
    cursor: pointer;
    font-size: 12px;
}

.my-component-update-button {
    width: 19.5rem;
    height: 2.5rem;
    border: none;
    border-radius: 15px;
    background-color: rgba(101, 106, 249, 1);
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-size: 16px;
    transform: translateX(3.5rem) translateY(-1rem);
    cursor: pointer;
}

.my-component-update-button:focus {
    outline: none;
}

.my-component-close-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.my-component-close-button svg {
    transition: fill 0.3s ease;
}

.my-component-close-button svg path {
    fill: currentColor;
}

.my-component-close-button:hover svg path {
    fill: rgb(101, 106, 249);

}


@media (max-width:1161px) {
    .Savingcompany {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .my-component-favorite-companies {
        display: flex;
        justify-content: center;
    }
}

@media (max-width:1150px) {
    .Savingcompany {
        transform: translateX(4rem);
    }

    .my-component-favorite-companies {
        display: grid;
        justify-content: center;
        align-items: center;
        align-items: center;
        margin-left: 13rem;
    }
}

@media(max-width:1020px) {
    .Savingcompany {
        transform: translateX(8rem);
    }
}











@media (max-width:830px) {
    .Savingcompany {
        font-size: 15px;
    }
}

@media (max-width:805px) {
    .my-component-favorite-companies {
        transform: translateX(-3rem) translateY(2rem);
    }

    .my-component-sidebar {
        width: 21rem;
    }

    /* side abr width dasamoklebeli */
}

@media (max-width:750px) {
    .my-component-favorite-companies {
        transform: translate(-4.5rem) translateY(1rem);
    }
}




@media (max-width:690px) {
    .my-component-sidebar {
        width: 17rem;
    }

    .my-component-header {
        transform: translateX(-1rem) translateY(2rem);
    }

    .SavingCompanyIndustry {
        transform: translateX(1rem) translateY(-4rem);
    }


    .my-component-favorite-companies {
        transform: translateX(-6.5rem) translateY(1rem);
    }
}

@media (max-width:640px) {
    .my-component-favorite-companies {
        transform: translateX(-8rem);
    }
}

@media (max-width:590px) {
    .my-component-header {
        height: fit-content;
        font-size: 13px;
        transform: translateX(0.3rem) translateY(2rem);
    }

    .SavingCompanyIndustry button {
        width: 10rem;
    }

    .my-component-sidebar {
        width: 13rem;
    }



    .my-component-favorite-companies {
        transform: translateX(-9.5rem) translateY(1rem);
    }
}

@media (max-width:590px) {
    .Savingcompany {
        transform: translateX(6rem);
    }
}

@media (max-width:555px) {
    .Savingcompany {
        transform: translateX(4rem);
    }
}

@media (max-width:480px) {
    .Savingcompany {
        transform: translateX(1.5rem);
    }
}

@media(max-width:545px) {


    .my-component-favorite-companies {
        transform: translateX(-10.5rem)
    }
}

@media(max-width:530px) {
    .my-component-header {
        font-size: 9px;
        transform: translateX(-0.5rem) translateY(3rem);
    }

    .my-component-sidebar {
        width: 9rem;
    }

    .SavingCompanyIndustry button {
        width: 6.7rem;
        height: 70px;
    }


    .my-component-favorite-companies {
        transform: translate(-12.5rem) translateY(1rem);
    }

    .Leave-Page {
        width: 7.5rem;
        transform: translateY(-14.5rem) translateX(0.5rem);
    }

}

@media (max-width:400px) {
    .Leave-Page {
        transform: translateY(-11.5rem) translateX(0.5rem);
    }
}