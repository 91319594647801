.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--white);
  width: 80vw;
  height: 30vh;
  /* margin: 20vh auto 0;  */
}

.block {
  background-color:var(--darkgray);
  width: calc(30vh - 20px);
  height: calc(30vh - 20px);
  margin-top: 10px; 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}


@media (max-width: 768px) { .charts-row { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; } .chart-container{ width: 100%; } }