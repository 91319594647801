.banner-container {
  min-width: 50%;
  max-width: 78%;
  background-color: #656AF9;
  border-radius: 35px;
  padding: 20px;
  text-align: center;
  color: white;
  height: auto;
  margin-bottom: 1rem;
}

.banner-container h1 {
  font-size: 2.5rem;
  /* margin-bottom: 1rem; */
  width: 80%;
  margin: 1rem auto;
}

.features {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust opacity as needed */
  z-index: 999;
  /* Ensure this is higher than other elements */
}

.popup-content {
  position: relative;
  z-index: 1000;
  padding: 10px;
  border-radius: 10px;
}




.features span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cta-button {
  background-color: white;
  color: black;
  border: none;
  border-radius: 15px;
  padding: 0.5rem 2rem;
  font-size: 16px;
  cursor: pointer;
  margin-top: 1.5rem;
}

.close-overlay {
  border: none;
  width: 8rem;
  height: 2rem;
  font-weight: bold;
  transform: translateY(-1rem);
  border-radius: 10px;
  background-color: #656AF9;
  color: white;
}

.close-overlay:focus {
  outline: none;
}

.close-overlay:hover {
  cursor: pointer;
}

/* Responsive */

/* Phone */
@media(min-width:543px) {
  .banner-container h1 {
    font-size: 2.2rem;
  }
}

@media (max-width:542px) {
  .banner-container {
    max-width: 80%;
    transform: translateX(0.5rem);
  }

  .banner-container h1 {
    font-size: 1.5rem;
    width: 65%;
  }

  .features {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

/* Small size phone */

@media (max-width:350px) {
  .cta-button {
    width: fit-content;
    width: 70%;
  }
}