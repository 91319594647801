.MainContainerNews {
    display: flex;
    flex-direction: column;
    width: 81rem;
    max-width: 100%;
    height: fit-content;
    background: rgba(255, 255, 255, 1);
    border-radius: 15px;
    margin: 5rem auto;
    overflow: visible;
    transform: translateX(-1.5rem);
    padding: 1.5rem;
}

.AllContent-MainPageNews {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
}


/* Company title */
.CompanyNews-Formainpage {
    display: flex;
    justify-content: flex-start;
    transform: translateY(0rem) translateX(2.1rem);
}

.CompanyNews-Formainpage .Title {
    color: rgba(101, 106, 249, 1);
    font-weight: 600;
}

.CompanyNews-Formainpage .PartTitle {
    transform: translateY(1.3rem) translateX(-2rem);
    color: #000;
    font-weight: 600;
}




.SeeMore-News {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    transform: translateY(-4.5rem) translateX(-2rem);
}

.Media-Navbarnews {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 8rem;
    margin-left: 0.7rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    margin-top: 1.5rem;
    transform: translateX(-1rem);
}

.MainNews {
    transform: translateY(-3rem) translateX(-2rem);
}

.MainNews .fonticon {
    position: relative;
    margin-left: 0.5rem;
}


.btn:focus {
    outline: none;
}


/* Advertising */

.advertising {
    width: 12rem;
    height: 2.2rem;
    border: 1px, 1px, 0px, 1px;
    border-radius: 15px 15px 0px 0px;
    background-color: rgba(92, 209, 244, 1);
    cursor: pointer;
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-column: 4;
    grid-column: 4;
    margin-top: -2.2rem;
    margin-left: 6rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.advertising .bmge {
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
}

.See-News {
    color: #656AF9;
    font-weight: bold;
}


@media (max-width:580px) {
    .AllContent-MainPageNews {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3rem;
    }

    .SeeMore-News {
        transform: translateY(-5.5rem);
    }

    .CompanyNews-Formainpage {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-0.5rem);
    }

}

@media (max-width:483px) {
    .CompanyNewsTitle {
        font-size: 14px;
        transform: translateX(-5rem) translateY(3rem);
    }
}

@media (max-width:431px) {
    .CompanyNewsTitle {
        font-size: 13px;
        transform: translateX(-7rem) translateY(3rem);
    }
}

@media (max-width:376px) {
    .CompanyNewsTitle .Title {
        font-size: 13px;
        transform: translateX(7rem) translateY(-3rem);
    }

    .SeeMore-News {
        transform: translateY(-5.5rem);
    }
}

@media (max-width:395px) {
    .CompanyNews-Formainpage {
        font-size: 14px;
    }

    .advertising {
        max-width: 10rem;
    }
}