.BoxContainers-Search {
    width: 100%;
    max-width: 84rem;
    height: fit-content;
    max-height: 60rem;
    align-items: center;
    margin: 3rem auto;
    transform: translateY(1.5rem);
    color: black;
    background-color: #FFFFFF;
    border-radius: 13px;
    overflow-x: hidden;
    overflow-y: visible;
    scrollbar-width: thin;
    word-wrap: break-word;
    flex-wrap: wrap;
    display: flex;
}

.navbarContent-SearchComp {
    width: 100%;
    justify-content: center;
}

.BoxContainers-Search {
    display: flex;
}

.SearchingResult-SearchComp {
    grid-column: 1/3;
    margin-left: 3.1rem;
    margin-top: 0.5rem;
}

.Search-Sim {
    color: #000;
    max-width: 12rem;
    transform: translateY(-3.5rem);
}

.Update-Time {
    color: rgba(146, 152, 166, 1);
    font-weight: 500;
    transform: translateY(-1rem);
}

.List {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 4rem;
    transform: translateY(7.2rem) translateX(11rem);
}

.Name-Search {
    color: rgba(146, 152, 166, 1);
    font-weight: bold;
    transform: translateY(0.3rem);
}


.Active-Search {
    color: rgba(146, 152, 166, 1);
}



.Capital-Search {
    color: rgba(146, 152, 166, 1);
}



.Win-Search {
    color: rgba(146, 152, 166, 1);
}


.FullResponsible-Search {
    color: rgba(146, 152, 166, 1);
    text-indent: 1.3rem;
}

.breakAfter {
    display: block;
}


.Income-Search {
    color: rgba(146, 152, 166, 1);
    text-indent: 0.5rem;
}


.simple-select-Search {
    width: 195px;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 15px;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    background-color: white;
    grid-column: 7/8;
    color: #000;
    transform: translateX(-5rem) translateY(1.6rem);
}



.HorizontalLineXBog {
    width: 78rem;
    background-color: rgba(217, 219, 233, 1);
    margin-left: 1rem;
}

.BogImg {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    transform: translateX(0rem) translateY(2.5rem);
}

.SearchingCompany {
    max-width: 17rem;
    display: flex;
    align-items: center;
    transform: translateX(3rem) translateY(-0.6rem);
    color: #000;
}


.SeeCompanySearch {
    color: rgba(146, 152, 166, 1);
    font-weight: 400;
    transform: translateX(2.9rem) translateY(-5.8rem);
    display: flex;
    width: 4%;
    justify-content: center;
}

.upArrow {
    transform: translateX(0rem) translateY(-2px);
}

/* Starting Statistic Numbers */

.ActiveNumber {
    transform: translateY(1.7rem) translateX(6rem);
}

.NumForSearch {
    color: rgba(0, 0, 0, 1);
    transform: translateX(1rem);
}

.PercentSearch {
    background-color: pink;
    color: rgba(188, 0, 34, 1);
    border-radius: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    transform: translateY(-2.4rem) translateX(5.8rem);
}

/* Second */
.InfoBtn {
    transform: translateY(-4.8rem) translateX(8.5rem);
}

.RestOfAllNumber {
    transform: translateY(1.7rem) translateX(7rem)
}

.Num {
    color: rgba(0, 0, 0, 1);
}

.PercentGreenSearch {
    background-color: rgb(166, 243, 197);
    color: rgba(0, 188, 75, 1);
    border-radius: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    transform: translateY(-2.4rem) translateX(5.9rem);
}

.Compere-Saving {
    grid-column: 7;
    transform: translateY(2.3rem) translateX(1rem);
}

.CompereNavbar {
    color: rgba(101, 106, 249, 1);
    transform: translateY(-2.8rem) translateX(1.4rem);
}

.SavingNavbar {
    color: rgba(101, 106, 249, 1);
    transform: translateY(-6.4rem) translateX(1.6rem);
}

.PlusNavbar {
    transform: translateY(-3.5rem) translateX(0rem);
}

.PercentGreen {
    background-color: rgb(166, 243, 197);
    color: rgba(0, 188, 75, 1);
    border-radius: 5px;
    width: fit-content;
    transform: translateY(-2.5rem) translateX(6.5rem);
}

.PercentRed {
    background-color: pink;
    color: rgba(188, 0, 34, 1);
    border-radius: 5px;
    width: fit-content;
    transform: translateY(-2.4rem) translateX(5.8rem);
}

.ActiveNumber2 {
    transform: translateY(1.7rem) translateX(6rem);
}

.ActiveNumber2 .PercentGreen {
    transform: translateX(6rem) translateY(-2.4rem);
}



.search-result-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 295px repeat(6, 174px);
    grid-auto-rows: minmax(113px, auto);
    cursor: pointer;
    margin-left: 2rem;
    row-gap: 20px;
}

/* starting responsive */