.BoxContainers {
    max-width: 100%;
    width: 84rem;
    height: fit-content;
    align-items: center;
    margin: 9rem auto;
    transform: translateY(-4rem);
    color: black;
    background-color: #FFFFFF;
    border-radius: 13px;
    overflow-x: hidden;
    scrollbar-width: thin
}

.navbarContent-SimCompanies {
    display: -ms-grid;
    display: grid;
    overflow-y: hidden;
    overflow-x: hidden;
    grid-template-columns: 295px repeat(6, 174px);
    grid-template-rows: 200px;
    grid-auto-rows: 113px;
}



.SearchingResult-SimCompanies {
    grid-column: 1/2;
    margin-left: 2rem;
    margin-top: 1rem;
}


.Update-Time {
    color: rgba(146, 152, 166, 1);
    font-weight: 500;
    transform: translateY(-1rem);
}



.breakAfter {
    display: block;
}



.simple-select {
    width: 195px;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 15px;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    background-color: white;
    grid-column: 7/8;
    color: #000;
    transform: translateX(-5rem) translateY(1.6rem);
}

.HorizontalLineXBog {
    width: 78rem;
    background-color: rgba(217, 219, 233, 1);
    grid-column: auto;
    grid-row: auto;
    margin-left: 2rem;
    height: 1px;
}

.BogImg {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    transform: translateX(0rem) translateY(2rem);
}

.CompanyMainTitle {
    min-width: 12rem;
    max-width: 18rem;
    transform: translateX(2.4rem) translateY(-1.2rem);
    color: #000;
}

.SeeCompany {
    color: rgba(146, 152, 166, 1);
    font-weight: 400;
    transform: translateX(13.5rem) translateY(-4.5rem);
}

.upArrow {
    transform: translateX(0rem) translateY(3px);
}

/* Starting Statistic Numbers */

.ActiveNumber {
    transform: translateY(1.2rem) translateX(1rem)
}

.Num {
    color: rgba(0, 0, 0, 1);
    transform: translateX(0.8rem);
}

.Percent-sim {
    display: flex;
    justify-content: center;
    background-color: pink;
    color: rgba(188, 0, 34, 1);
    border-radius: 5px;
    width: fit-content;
    transform: translateY(-2.5rem) translateX(5.8rem);
}

/* Second */
.InfoBtn {
    margin-left: 0.6rem;
    margin-top: -0.5rem;
}

.InfoBtn2 {
    transform: translateY(-4.9rem) translateX(8.4rem);
}

.RestOfAllNumber {
    transform: translateY(1.2rem) translateX(1rem)
}

.Num {
    color: rgba(0, 0, 0, 1);
}

.PercentGreen {
    background-color: rgb(166, 243, 197);
    color: rgba(0, 188, 75, 1);
    border-radius: 5px;
    width: fit-content;
    transform: translateY(-2.4rem) translateX(5.7rem);
}

.Compere-Saving {
    -ms-grid-column: 7;
    grid-column: 7;
    -webkit-transform: translateY(2.3rem) translateX(1rem);
    -ms-transform: translateY(2.3rem) translateX(1rem);
    transform: translateY(2.3rem) translateX(1rem);
}

.CompereNavbar {
    color: rgba(101, 106, 249, 1);
    -webkit-transform: translateY(-2.8rem) translateX(1.4rem);
    -ms-transform: translateY(-2.8rem) translateX(1.4rem);
    transform: translateY(-2.8rem) translateX(1.4rem);
}

.SavingNavbar {
    color: rgba(101, 106, 249, 1);
    -webkit-transform: translateY(-6.4rem) translateX(1.6rem);
    -ms-transform: translateY(-6.4rem) translateX(1.6rem);
    transform: translateY(-6.4rem) translateX(1.6rem);
}

.PlusNavbar {
    transform: translateY(-3.5rem) translateX(0rem);
}

.List2 {
    display: flex;
    padding: 7rem;
    display: none;
}

.List2 div {
    padding: 1.5rem;
    max-width: 14rem;
}

.List2 .FullActive-Sim {
    height: fit-content;
    max-width: 1rem;
}