@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@100..900&display=swap');

.main-nav,
.sub-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 60px;
  font-family: "BPG Arial", sans-serif;
  overflow-x: auto;
  /* Enable horizontal scrolling */
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
  -ms-overflow-style: none;
  /* Hide scrollbar for Internet Explorer and Edge */
  padding-left: 10px;
  /* Add padding to ensure first item is fully visible */
  padding-right: 10px;
  /* Add padding to ensure last item is fully visible */
}

.main-nav {
  width: 100%;
  margin: 0 auto;
  height: 8.5vh;
  border-bottom: 1px solid grey;
  margin-top: -5rem;
  background-color: white;
}

.sub-nav {
  background-color: var(--white);
  -webkit-transform: translateY(-6rem);
  -ms-transform: translateY(-6rem);
  transform: translateY(-6rem);
  height: 5rem;
}

.nav-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  font-family: "Noto Sans Georgian", sans-serif;
  color: grey;
}

.nav-btn:focus {
  outline: none;
}

.icon-wrapper {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 35%;
  background-color: rgb(236, 236, 236);
  color: rgb(143, 143, 143);
  /* Color of the icon */
}

.nav-btn.active {
  color: #656AF9;
  /* Change font color for active state */
}

.nav-btn.active::after {
  content: '';
  position: absolute;
  bottom: -5px;
  /* Adjust this value to control the distance from the text */
  left: 0;
  right: 0;
  height: 3px;
  background-color: #656AF9;
  margin: 0 auto;
  margin-bottom: 3px;
}

.nav-btn.active .icon-wrapper {
  background-color: #eaeaff;
  color: #656AF9;
}



.main-nav::-webkit-scrollbar,
.sub-nav::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for WebKit browsers */
}


@media(max-width:1040px) {
  .nav-btn {
    transform: translateX(6rem);
  }
}

@media(max-width:860px) {
  .nav-btn {
    transform: translateX(11rem);
  }
}

@media(max-width:690px) {
  .nav-btn {
    transform: translateX(1rem);
  }
}

@media(max-width:689px) {
  .nav-btn {
    transform: translateX(13rem);
  }
}

@media(max-width:625px) {
  .nav-btn {
    transform: translateX(18rem);
  }
}

@media(max-width:483px) {
  .nav-btn {
    transform: translateX(23rem);
  }
}