  .navbars {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 1.5rem;
    margin: 0 auto;
    background-color: white;
  }

  .navbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    max-width: 141rem;
    padding: 0 2rem;
  }

  .navbar-login-MainPage {
    position: absolute;
    right: 0;
    width: 100%;
    max-width: 20rem;
    display: flex;
    justify-content: end;
    transform: translateX(-3rem) translateY(-1.65rem);
  }

  .navbar-login-MainPage button {
    width: 100%;
    max-width: 8.5rem;
    height: 2.1rem;
    border: none;
    border-radius: 15px;
    background-color: rgba(101, 106, 249, 0.1);
    color: rgba(101, 106, 249, 1);
    cursor: pointer;
    font-size: 0.8rem;
    transform: translateY(1.7rem);
  }

  .LineMainPage {
    color: rgba(101, 106, 249, 1);
    display: flex;
    height: 2rem;
  }

  .hotline {
    transform: translateX(-10.5rem) translateY(-1.2rem);
    font-size: 0.8rem;
  }

  .Faphone {
    font-size: 15px;
    transform: translateX(-2rem) translateY(0.6rem);
  }

  .navbar-logo img {
    display: flex;
    max-width: 5rem;
    height: fit-content;
    margin-top: 0.5rem;
    transform: translateX(2rem);
  }

  .navbar-logo img:focus {
    outline: none;
  }


  .suggestions::-webkit-scrollbar-thumb {
    background: rgb(131, 117, 255);
    border-radius: 5px;
  }

  .suggestions li {
    padding: 8px;
  }

  .suggestions li:hover {
    background-color: #f0f0f0;
  }

  .navbar-search input[type='text']:focus {
    outline: none;
  }

  .navbar-profile {
    color: #656AF9;
    text-decoration: none;
    font-size: 15px;
    transform: translateY(1.8rem) translateX(1rem);
  }


  .FaUser {
    font-size: 15px;
    transform: translateX(-0.3rem) translateY(-0.1rem);
  }

  @media (max-width: 650px) {
    .LineMainPage {
      display: none;
    }
  }


  @media (max-width: 490px) {
    .navbar-logo img {
      transform: translateX(-2rem);
      width: 90%;
      height: fit-content;
    }
  }