/* globally color */

body {
  overflow-x: hidden;
}

:root {
  --blue: #656AF9;
  --darkgray: #9298A6;
  --red: #BC0022;
  --green: #00BC4B;
  --yellow: #FFB800;
  --black: #000000;
  --lightgray: #F7F9FC;
  --white: #FFFFFF;
  --lightblue: #5CD1F4;
  --orange: #FF7A00;
  --darkred: #BC002226;
}

*::after,
*::before,
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}