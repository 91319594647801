.Main-Container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 26rem;
    height: 23rem;
    border-radius: 15px;
    /* background-color: rgba(255, 255, 255, 1); */
    overflow-y: hidden;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: white;
    padding: 1rem;
    border-radius: 10px;
    position: relative;
}

.Navbar-Content {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (300px)[1];
    grid-template-columns: repeat(1, 300px);
    -ms-grid-rows: (67px)[7];
    grid-template-rows: repeat(7, 67px);
    /* Increased row count */
}

.Navbar-Content>*:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}

.Navbar-Content>*:nth-child(2) {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
}

.Navbar-Content>*:nth-child(3) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
}

.Navbar-Content>*:nth-child(4) {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
}

.Navbar-Content>*:nth-child(5) {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
}

.Navbar-Content>*:nth-child(6) {
    -ms-grid-row: 6;
    -ms-grid-column: 1;
}

.Navbar-Content>*:nth-child(7) {
    -ms-grid-row: 7;
    -ms-grid-column: 1;
}

.AuthorizationButton {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 5;
    grid-row: 5/5;
    margin-top: 1.5rem;
}

/* ავტორიზაციის სათაური */
.AuthorizationTitle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 4.5rem;
    -ms-grid-row: 1;
    grid-row: 1/1;
}

.TitleAuthorization {
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    line-height: 18px;
    -webkit-transform: translateX(1rem);
    -ms-transform: translateX(1rem);
    transform: translateX(1rem);
}

/* ტელეფონის ნომერი */
.PhoneNumber {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-top: 5rem;
    -ms-grid-row: 2;
    grid-row: 2/2;
    width: 24rem;
    -webkit-transform: translateX(-5.5rem);
    -ms-transform: translateX(-5.5rem);
    transform: translateX(-5.5rem);
}

.InputNum {
    width: 19rem;
    height: 2.5rem;
    border-radius: 15px;
    border: 1.5px solid rgba(218, 218, 218, 0.2);
    background-color: rgba(218, 218, 218, 0.2);
    text-indent: 5.5rem;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    margin-top: 0rem;
    margin-left: -0.4rem;
}

.InputNum:focus {
    outline: none;
}

.InputNum::-webkit-input-placeholder {
    font-size: 14px;
    color: #9298A6;
}

.InputNum::-moz-placeholder {
    font-size: 14px;
    color: #9298A6;
}

.InputNum:-ms-input-placeholder {
    font-size: 14px;
    color: #9298A6;
}

.InputNum::-ms-input-placeholder {
    font-size: 14px;
    color: #9298A6;
}

.InputNum::placeholder {
    font-size: 14px;
    color: #9298A6;
}

.Flag {
    width: 15px;
    height: 15px;
    -webkit-transform: translateX(6.2rem) translateY(2px);
    -ms-transform: translateX(6.2rem) translateY(2px);
    transform: translateX(6.2rem) translateY(2px);
}

/* ნომრის პირველი 3 ციფრი */
.StartingNum {
    -webkit-transform: translateX(6.6rem);
    -ms-transform: translateX(6.6rem);
    transform: translateX(6.6rem);
    color: #000;
}

/*ახალი ინფათი ესემეს კოდის*/
.SmsCode {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    outline: none;
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 3;
    grid-row: 3;
    margin-top: 2rem;
}

.Sms {
    width: 19rem;
    height: 2.5rem;
    border-radius: 15px;
    border: 1.5px solid rgba(218, 218, 218, 0.2);
    background-color: rgba(218, 218, 218, 0.2);
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    text-indent: 24px;
    margin-top: 1.2rem;
}

.Sms::-webkit-input-placeholder {
    font-size: 14px;
    color: #9298A6;
}

.Sms::-moz-placeholder {
    font-size: 14px;
    color: #9298A6;
}

.Sms:-ms-input-placeholder {
    font-size: 14px;
    color: #9298A6;
}

.Sms::-ms-input-placeholder {
    font-size: 14px;
    color: #9298A6;
}

.Sms::placeholder {
    font-size: 14px;
    color: #9298A6;
}

.Sms:focus {
    outline: none;
}

/* ღილაკის გაკეთება */
/* .SmsCode .ReceiveSms{
    transform:translateX(2rem);
    margin-top:1.4rem;
} */
.ReceiveSms {
    width: 113px;
    height: 34px;
    border: none;
    border-radius: 15px;
    background-color: rgba(101, 106, 249, 0.15);
    font-size: 12px;
    color: rgba(101, 106, 249, 1);
    font-weight: bold;
    cursor: pointer;
}

.ReceiveSms:focus {
    outline: none;
}

/* დამახსოვრება */
.Remember-Container {
    grid-row: 4/4;
}

.Remember {
    display: flex;
    transform: translateY(0.2rem) translateX(1.7rem);
    margin-top: 1rem;
    font-weight: 500;
    color: black;
}

/* Square Check */
.Check {
    color: rgba(101, 106, 249, 1);
    cursor: pointer;
    display: flex;
    transform: translateY(2.3rem) translateX(0.4rem);
}

/* მთავარი ავტორიზაცია */
.MainAuthorization {
    background-color: rgba(101, 106, 249, 1);
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    font-weight: bold;
    margin-top: 0.5rem;
    width: 19rem;
    height: 2.5rem;
    border: none;
    border-radius: 15px;
}

.MainAuthorization:focus {
    outline: none;
}

/* Create Account */
.CreateAccount {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 6;
    grid-row: 6/6;
    text-align: center;
    margin-top: 1rem;
    cursor: pointer;
}

/* დაკავშრება გუგლის და აიოსის მეშვეობით */
.Connect {
    width: 8.5rem;
    height: 2.3rem;
    border-radius: 15px;
    background-color: rgba(247, 249, 252, 1);
    cursor: pointer;
    border: none;
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 7;
    grid-row: 7/7;
}

.Connect:focus {
    outline: none;
}

/* Fade-in and Fade-out keyframes */
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

/* Error message animation */
.ErrorMessage {
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
}