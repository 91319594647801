.MainFinancial {
  width:100%;
  max-width:108rem;
  height:fit-content;
  border-radius: 15px;
  background-color: var(--white);
  margin: 7rem auto;
  overflow: visible;
  padding:2.5rem;
}

.Main-Content {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  width: 100%;
  height: auto;
  gap: 1.5rem;
}

/* Year selection button styles */
.ChooseYear {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
          transform: translateX(-2.8rem) translateY(-4rem);
}

.ChooseYear .SomeYear {
  width: 10rem;
  height: 2.7rem;
  border: 1px solid rgba(229, 229, 229, 1);
  background-color: var(--white);
  color: hsl(0, 0%, 0%);
  text-indent: 0.5rem;
  font-size: 14px;
  border-radius: 15px;
  cursor: pointer;
  margin-right: 2.3rem;
  margin-top: 1rem;
}
.ChooseYear .SomeYear:focus {
  outline: none;
}

/* Text styles */
.FinancialYear {
  margin-top: 1rem;
  margin-left: 2.5rem;
  -ms-grid-column-span: 2;
  grid-column: span 2;
}

.FinancialYear .Time {
  color: var(--darkgray);
  font-weight: 0;
  margin-top: -1rem;
}
.FinancialYear .Fin {
  color: var(--black);
  font-size: 15px;
}

/* Container styles */
.fullAssets {
  width: 200px;
  height: fit-content;
  background-color: var(--lightgray);
  font-weight: bold;
  border-radius: 15px;
  margin-left: 2rem;
  position: relative;
}

/* Elements inside fullAssets design */
.title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  color: var(--black);
  font-size: 15px;
  margin-top: 0.9rem;
  
}
.fullAssets .numbers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  color: var(--blue);
  margin-top: -1.3rem;
  margin-left: 2.2rem;
}
.fullAssets .ExactlyNum {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  margin-top: -1rem;
  margin-left: 3.7rem;
  position: relative;
  width: 70px;
  height: 23px;
  border-radius: 5px;
  font-weight: bold;
}

/* Green and red background for changePercentage */
.ExactlyNum.green {
  background-color: rgba(0, 188, 75, 0.15); /* Light green background */
  color: var(--green); /* Green text color */
}
.ExactlyNum.red {
  background-color: rgba(255, 0, 0, 0.15); /* Light red background */
  color: var(--red); /* Red text color */
}

/* Triangle indicators */
.ExactlyNum.green::before, .ExactlyNum.red::before {
  content: '';
  position: absolute;
  left: -15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 0;
  height: 0;
}
.ExactlyNum.green::before {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 10px solid var(--green);
}
.ExactlyNum.red::before {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid var(--red);
}


/* Responsive web design */

@media (max-width:725px){
    .ChooseYear{
      transform:translateY(-6.3rem);
    }
    .Fin{
      transform:translateY(2rem);
    }
    .Time{
      transform:translateY(2rem);
    }
}
/* telephone */
@media (max-width:543px){
  .Main-Content{
    display:flex;
    flex-direction:column;
    align-items:center;
    transform:translateY(-2.5rem);
  }
  .Fin{
    transform:translateY(0.5rem);
  }
  .Time{
    transform:translateY(0.5rem);
  }
  .ChooseYear{
    transform:translateY(-8rem);
  }
}

/* extra small */
@media (max-width:452px){
  .ChooseYear{
    transform:translateY(-9rem);
  }
}

.SkeletonLoader-10{
  width: 210px; /* Width of the rectangle */
  height: 110px; /* Height of the rectangle */
  background: linear-gradient(-90deg, #f5f5f5 25%, #ffffff 50%, #f5f5f5 75%);
  border: 1.5px solid #E0E0E0;
  background-size: 400% 100%;
  animation: skeleton-loading 2.2s ease-in-out infinite;
  border-radius: 4px; /* Rounded corners */
  margin-bottom: 10px; /* Adjust margin as needed */
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}