@import 'normalize.css';

/* GLobally  */
*::before,
*::after,
*{
  box-sizing:border-box;
}

*{
  font-family: 'AvenirNextGeorgianRegular', sans-serif ;
}

@font-face {
  font-family: 'AvenirNextGeorgianRegular';
  src: url('./fonts/Linotype\ -\ Avenir\ Next\ Georgian\ Demi\ copy.otf') format('opentype');
  /* font-weight: bold; */
  font-style: normal;
}


body{
  background-color: #f7f9fc;
}