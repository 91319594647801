.LastView-Container {
    position: relative;
    width: 100%;
    max-width: 38rem;
    height: 26.3rem;
    border-radius: 15px;
    background-color: #FFFF;
    color: black;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: rgb(214, 214, 214) transparent;
    transform: translateY(-0.3rem);
}

.LastView-Context>*:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}

.LastViewHeader-Title {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1000;
    height: 4rem;
}

.LastViewHeader-Title .LastView {
    color: #000;
    font-weight: 500;
    cursor: pointer;
    width: 150px;
    height: 30px;
    margin-left: 2.5rem;
}

.LastView-MainRow .FIRSTROW-BOG {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    cursor: pointer;
    -webkit-transform: translateY(1rem);
    -ms-transform: translateY(1rem);
    transform: translateY(1rem);
    z-index: 0;
    margin-left: 2rem;
}

.LastView-MainRow .BOGNAME {
    color: #000;
    font-weight: 500;
    margin-left: 3rem;
    transform: translateY(-2.3rem) translateX(2rem);
    z-index: 0;

}

.LastView-MainRow .CompereItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    color: #656AF9;
    font-weight: lighter;
    margin-top: -6rem;
    -webkit-transform: translateX(1rem);
    -ms-transform: translateX(1rem);
    transform: translateX(1rem);
    cursor: pointer;
    z-index: 0;
}


.LastView-MainRow .SaveItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-transform: translateX(0.2rem);
    -ms-transform: translateX(0.2rem);
    transform: translateX(0.2rem);
    margin-top: -0.5rem;
    color: #656AF9;
    font-weight: 500;
    cursor: pointer;
    z-index: 0;
}

/* Rest of your existing CSS for OrdinaryRow */
.LastView-OrdinaryRow {
    margin-top: 1rem;
    margin-left: 2rem;
    z-index: 0;
}

.LastView-OrdinaryRow .Ordinary-BOG {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    cursor: pointer;
    -webkit-transform: translateY(0.5rem);
    -ms-transform: translateY(0.5rem);
    transform: translateY(0.5rem);
}

.LastView-OrdinaryRow .BOGNAMEE {
    color: #000;
    font-weight: 500;
    margin-left: 3rem;
    transform: translateY(-3rem);
}

.LastView-OrdinaryRow .CompereItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    color: #656AF9;
    font-weight: lighter;
    margin-top: -6.5rem;
    transform: translateX(1rem);
}

.LastView-OrdinaryRow .CompereItem:hover {
    cursor: pointer;
}

.LastView-OrdinaryRow .SaveItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-transform: translateX(0.2rem);
    -ms-transform: translateX(0.2rem);
    transform: translateX(0.2rem);
    margin-top: -0.5rem;
    color: #656AF9;
    font-weight: 500;
}

.LastView-OrdinaryRow .SaveItem:hover {
    cursor: pointer;
}

.last-seen-border-color {
    border: none;
    background-color: #D9DBE9;
    height: 1px;
}


/* responsive web design */


@media (max-width:440px) {
    .together {
        display: flex;
        transform: translateX(5rem);
    }

    .LastView-MainRow {
        transform: translateX(2.8rem) translateY(-1.2rem);
    }

    .LastViewHeader-Title .LastView {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85%;
    }

    .together .CompereItem {
        height: fit-content;
        transform: translateY(3rem);
    }

    .together .SaveItem {
        transform: translateY(-2.4rem) translateX(-1.5rem);
    }

    .last-seen-border-color {
        width: 100%;
        margin-left: -24rem;
    }


    .LastView-MainRow .FIRSTROW-BOG {
        transform: translateY(0.8rem);
    }

    .LastView-OrdinaryRow .Ordinary-BOG {
        transform: translateY(0.1rem);
    }

    .LastView-OrdinaryRow {
        transform: translateY(-1rem) translateX(2.8rem)
    }

    .LastView-OrdinaryRow .together .CompereItem {
        transform: translateX(-2.2rem) translateY(3rem);
    }

    .LastView-OrdinaryRow .together .SaveItem {
        transform: translateX(-3.2rem) translateY(-3rem);
    }

}