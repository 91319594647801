.navbar-second {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  row-gap: 1rem;
  background-color: var(--lightgray);
  padding: 0.4rem;
  z-index: 1000;
}

.navbar-search,
.navbar-login-second {
  display: flex;
  align-items: center;
  transform: translateY(0rem);

}

/* აქიდან კონტრ */
.navbar-search>div,
.navbar-login-second>div {
  margin: 0 30px;
}

.navbar-search {
  position: relative;
  flex: 1;
}



.navbar-login-second {
  flex: 1;
  justify-content: end;
}

/* Navbar */

.Search .navbar-search input[type='text'] {
  border: none;
  border-radius: 20px;
  width: 15rem;
  height: 2.3rem;
  text-indent: 0.5rem;
  /* margin-top: 1.2rem; */
  font-size: 0.8rem;
  /* Decreased font size */
}

.navbar-search input[type='text']::-webkit-input-placeholder {
  font-size: 0.8rem;
  /* Decreased placeholder font size */
}

.navbar-search input[type='text']::placeholder {
  font-size: 0.8rem;
  /* Decreased placeholder font size */
}



.navbar-search input[type='text'] {
  padding: 8px 30px 8px 40px;
  border: none;
  border-radius: 20px;
  background: #ffffff url('../../images/SearchIcon.png') no-repeat 10px center;
  background-size: 15px 15px;
}



.loading-animation-container {
  position: absolute;
  top: 20%;
  left: 22.5%;
}

.loading-animation {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-top-color: rgb(131, 117, 255);
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.suggestions::-webkit-scrollbar {
  width: 10px;
  /* Width of the scrollbar */
}

.suggestions::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the track */
}

.suggestions::-webkit-scrollbar-thumb {
  background: rgb(131, 117, 255);
  /* Color of the thumb */
  border-radius: 5px;
  /* Rounded corners of the thumb */
}

.suggestions::-webkit-scrollbar-thumb:hover {
  background: rgb(131, 117, 255);
  /* Color of the thumb on hover */
}

.suggestions {
  position: absolute;
  /* Keep it positioned relative to the parent */
  top: calc(100% + 0.5rem);
  /* Ensure it is always below the search bar */
  left: 8rem;
  top: 1.5rem;
  width: 100%;
  max-width: 18rem;
  max-height: 13rem;
  /* Allow up to 5 suggestions */
  min-height: 5rem;
  /* Minimum height to ensure visibility */
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;
  background-color: white;
  /* border: 1px solid #ccc; */
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Adjust shadow effect */

}

.suggestions li {
  padding: 5px 10px;
  /* Add some padding for better spacing */
  cursor: pointer;
  list-style: none;
  background-color: white;
  text-decoration: none;
}

.suggestions li:hover {
  background-color: #f0f0f0;
}

.navbar-search input[type='text']:focus {
  outline: none;
}

/* Navbar logo */
.logopositional {
  transform: translateY(0.3rem);
}

.navbar-logo-second img {
  display: flex;
  max-width: 5rem;
  height: fit-content;
}

.navbar-logo-second img:focus {
  outline: none;
}

/* აუტორიზაცია და პირადი გვერდი */

.navbar-login-second {
  width: 100%;
  max-width: 24rem;
}

.navbar-login-second button {
  width: 100%;
  max-width: 8.5rem;
  border: none;
  border-radius: 15px;
  background-color: rgba(101, 106, 249, 0.1);
  color: rgba(101, 106, 249, 1);
  cursor: pointer;
  font-size: 0.8rem;
  /* Decreased font size */
  height: 2.1rem;
  transform: translateX(-2rem);
}

/* ტელეფონი */



.LineMainPage-second {
  color: rgba(101, 106, 249, 1);
  display: flex;
}

.LineMainPage-second .hotline-second {
  font-size: 0.8rem;
  transform: translateX(-2.2rem);
  width: 8rem;
}

.Faphone-second {
  transform: translateX(6.1rem) translateY(30px);
  font-size: 15px;

}


/* piradigverdi */
.navbar-profile-second {
  display: flex;
  width: 100%;
  max-width: 10rem;
  text-decoration: none;
  color: #656AF9;
  font-size: 15px;
  transform: translateX(-0.7rem);
}

.FaUser-second {
  transform: translateX(-0.4rem);
}

@media (max-width:798px) {
  .hotline-second {
    display: none;
  }

  .Faphone-second {
    display: none;
  }

  .navbar-second {
    padding: 1.3rem;
  }
}

@media (max-width:691px) {
  .navbar-login-second button {
    transform: translateY(-0.3rem);
  }

  .navbar-second {
    display: flex;
    flex-direction: column;
  }

  .logopositional {
    transform: translateX(-5rem);
  }

  .navbar-search input {
    transform: translateX(-12rem) translateY(2.7rem);
  }
}

@media (max-width:690px) {
  .navbar-profile-second {
    transform: translateX(4rem);
  }
}


@media (max-width:565px) {
  .logopositional {
    transform: translateX(-2.1rem);
  }

  .navbar-search input {
    transform: translateX(-9.5rem) translateY(2.7rem);
  }
}


@media (max-width:450px) {
  .logopositional {
    transform: translateX(-1.4rem);
  }

  .navbar-search input {
    width: 12rem;
    transform: translateX(-8.5rem) translateY(2.7rem);
  }

  .navbar-profile-second {
    transform: translateX(2.5rem);
  }
}

@media (max-width:400px) {
  .navbar-search input {
    width: 10rem;
  }
}

@media(max-width:352px) {
  .navbar-search input {
    width: 8.5rem;
  }
}

@media(max-width:320px) {
  .logopositional {
    transform: translateX(-1.7rem);
  }

  .navbar-search input {
    width: 7.5rem;
    transform: translateX(-9rem) translateY(2.7rem);
  }
}

.suggestions-empty {
  background: none;
  /* Remove the background */
  border: none;
  /* Remove any border */
  box-shadow: none;
  /* Adjust shadow effect */
}