.MainNavbar {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 5rem 2rem;
  max-width: 105%;
  transform: translateY(-1rem);
  overflow-x: hidden;
}

.NavbarInfo {
  display: flex;
  align-items: center;
  gap: 15px;
  transform: translateX(5rem);
  flex: 1;
}

.LogoCompany {
  width: 6.5vw;
  max-width: 100px;
  height: auto;
  border-radius: 100%;
  cursor: pointer;
  /* transform: translateX(-24rem) translateY(0.5rem); */
}

.LogoCompany:focus {
  outline: none;
}

.fa-building {
  position: relative;
  color: rgba(146, 152, 166, 1);
  font-size: 18px;
  transform: translateX(-10px);
}

.organisation {
  display: flex;
  transform: translateY(-1rem);
  align-items: center;
  font-size: 13px;
  letter-spacing: 1px;
  color: #9298A6;
  font-family: 'Franklin Medium', 'Arial Narrow', Arial, sans-serif;
}

.Tegetatitle {
  letter-spacing: 2px;
  font-family: 'Avenir Next', Georgian, sans-serif;
  position: relative;
  transform: translateY(1.3rem) translateX(-8.1rem);
  min-width: 13rem;
  max-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Submit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  /* Adjust padding as needed */
  width: auto;
  /* Adjust width as needed or remove it */
  height: 2.4rem;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  background-color: #656AF9;
  color: white;
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: 30px;
  transform: translateX(-27rem) translateY(-0.2rem);
}

.Submit:focus {
  outline: none;
}

.fa-arrows-left-right {
  position: static;
  color: white;
  font-size: 1rem;
  /* Adjust size as needed */
  margin-right: 10px;
}

.fa-paperclip,
.fa-check {
  width: 20px;
  transform: translateX(3.9rem) translateY(1.7rem);
  height: 20px;
  color: #656AF9;
  font-size: 20px;
  cursor: pointer;
  transition: color 1s, opacity 1s;
  /* Add transition for smooth effect */
}

.fa-check {
  color: green;
  opacity: 1;
}

.links {
  position: relative;
  left: 1.5rem;
  transform: translateY(-0.6rem) translateX(4rem);
  font-size: 14px;
  cursor: pointer;
  color: #656AF9;
  transition: color 1s, opacity 1s;
  /* Add transition for smooth effect */
}

/* აქიდან ხდება ტიჩკის პოზიციის კონტროლი */
.copy-success {
  color: green;
  opacity: 1;
}

.fa-plus {
  position: relative;
  padding-top: 2.4rem;
  width: 20px;
  height: 20px;
  color: #778899;
  font-size: 20px;
  cursor: pointer;
  transform: translateX(-2rem) translateY(2px);
}

.Saving {
  cursor: pointer;
  font-size: 14px;
  padding-top: 2.3rem;
  color: #9298A6;
  transform: translateX(2rem) translateY(3px);
}

.fa-file {
  position: relative;
  left: 0.4rem;
  padding-top: 2.2rem;
  padding-left: 1rem;
  height: 1.2rem;
  color: #778899;
  font-size: 20px;
  cursor: pointer;
}

.Download {
  padding-top: 2.2rem;
  cursor: pointer;
  font-size: 14px;
  color: #9298A6;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* High z-index to cover other content */
}

.copy-message {
  position: absolute;
  transform: translateY(-5.2rem) translateX(3.5rem);
  background-color: black;
  border-radius: 5px;
  color: #FFF;
  width: 7rem;
  height: 1.7rem;
  text-indent: 10px;
  transition: opacity 1s;
}

.copy-container {
  align-items: center;
  cursor: pointer;
  transform: translateY(1.2rem) translateX(-1rem);
}

.fa-icon {
  margin-right: 8px;
}

.fa-plus {
  width: 20px;
  transform: translateX(2.7rem) translateY(0rem);
  height: 20px;
  color: #656AF9;
  font-size: 20px;
  cursor: pointer;
  transition: color 1s, opacity 1s;

  /* Add transition for smooth effect */
}

.fa-check {
  color: green;
  opacity: 1;
  transform: translateX(2.7rem) translateY(1.2rem);

}

.Saving {
  cursor: pointer;
  font-size: 14px;
  padding-top: 2.3rem;
  color: #656AF9;
  transition: color 1s, opacity 1s;
  transform: translateX(2.8rem);
  display: flex;
}

.power {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
}

.save-success {
  color: green;
  opacity: 1;
}




.HeaderCircle-Icon {
  position: relative;
  width: 110px;
  height: 110px;
  border-radius: 100%;
  transform: translateX(-2rem) translateY(0rem);
  background-color: #f7f9fc;
  background-color: var(--darkgray);
  background-color: rgb(235, 240, 252);
}

.building-icon {
  position: absolute;
  left: 35%;
  top: 33%;
}


@media (max-width:1048px) {

  .power {
    transform: translateX(-2rem);
  }
}

@media (max-width:995px) {
  .power {
    transform: translateX(-5rem);
  }

}


@media(max-width:840px) {
  .power {
    transform: translateX(-10rem);
  }
}

@media (max-width: 684px) {
  .MainNavbar {
    flex-direction: column;
    /* padding: 2rem 1rem; */
    max-width: 120%;
  }


  .NavbarInfo {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    transform: none;
    transform: translateY(2rem);
  }

  .LogoCompany {
    width: 20vw;
    transform: none;
    /* Reset transform for smaller screens */
  }

  .organisation {
    transform: none;
    font-size: 12px;
    margin-top: -0.1rem;
  }

  .Tegetatitle {
    transform: none;
    text-align: center;
    transform: translateY(-2.5rem);
  }

  .Submit {
    transform: none;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }

  .fa-paperclip,
  .fa-check {
    display: none;
  }

  .links {
    transform: none;
    /* Reset transform for smaller screens */
  }

  .fa-plus {
    display: none;
  }

  .power {
    transform: translateY(-6.5rem);
  }

  .power .copy-container {
    transform: translateY(17px) translateX(-0.5rem);
  }

  .Saving {
    transform: none;
    margin-top: 1rem;
  }

  .HeaderCircle-Icon {
    transform: none;
    margin-bottom: 1rem;
  }
}