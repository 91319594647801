.skeleton-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px;
  width: 100%;
  background-color: white;
  height: 16.3rem;
}

.skeleton-logo,
.skeleton-title,
.skeleton-subtitle,
.skeleton-button,
.skeleton-link {
  background: -webkit-gradient(linear, right top, left top, color-stop(25%, rgb(228, 228, 228)), color-stop(50%, var(--lightgray)), color-stop(75%, rgb(228, 228, 228)));
  background: -o-linear-gradient(right, rgb(228, 228, 228) 25%, var(--lightgray) 50%, rgb(228, 228, 228) 75%);
  background: linear-gradient(-90deg, rgb(228, 228, 228) 25%, var(--lightgray) 50%, rgb(228, 228, 228) 75%);
  background-size: 400% 400%;
  -webkit-animation: shine 1.5s ease-in-out infinite;
          animation: shine 1.5s ease-in-out infinite;
}

.skeleton-logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-left: 5rem;
}

.skeleton-details {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-left: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.skeleton-text-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.skeleton-title,
.skeleton-subtitle {
  height: 30px;
  border-radius: 4px;
}

.skeleton-title {
  width: 100px;
  margin-bottom: 10px;
}

.skeleton-subtitle {
  width: 250px;
  margin-right: 10px;
}

.skeleton-button {
  width: 150px;
  height: 30px;
  border-radius: 15px;
  margin-left: 3rem;
}

.skeleton-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 25rem;
  margin-top: 3rem;

}

.skeleton-link {
  width: 100px;
  height: 20px;
  border-radius: 4px;
  /* gap: 2rem; */
  margin-right: 2rem;
}

@-webkit-keyframes shine {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes shine {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}