.Main-ContainerMainSearching-second {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 80%;
    max-width: 50rem;
    height: fit-content;
    border-radius: 15px;
    overflow-y: hidden;
    padding-top: 7rem;
}

/* All Items */
.AllItems-second {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    color: black;
}

/* Starting Title */
.startingTitle-second {
    display: flex;
    justify-content: center;
}

.FinancialInformation-second {
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    transform: translateY(-4rem);
}

/* Search Bar */
.SearchBar-second {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-1.8rem);
}

.search-second {
    width: 100%;
    max-width: 38rem;
    height: 4rem;
    border: 3px solid rgba(101, 106, 249, 1);
    border-radius: 15px;
    text-indent: 3rem;
}

.no-focus-outline-second:focus {
    outline: none;
}

.search-results-second {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 34.7rem;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow-y: auto;
    max-height: 200px;
    margin-top: -20px;
    /* Scrollbar styling */
    scrollbar-width: thin;
    scrollbar-color: #655AF9 #e0e0e0;
    border-radius: 10px;
}

.search-result-second {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.search-result-second:last-child {
    border-bottom: none;
}

.search-result-second:hover {
    background-color: #f9f9f9;
}

.search-icon-second {
    position: relative;
    transform: translateX(2.3rem);
    font-size: 18px;
}

/* Company Container */
.CompanyContainer-second {
    display: grid;
    column-gap: 1.3rem;
    grid-template-columns: repeat(auto-fit, minmax(8.5rem, 1fr));
    width: 100%;
    max-width: 40rem;
    height: 2.5rem;
    border-radius: 15px;
    align-items: center;
    transform: translateY(-1rem) translateX(0.5rem);
}

.Company1-second {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2.5rem;
    border-radius: 15px;
    background-color: #F7F9FC;
}

.Company1-second img {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    margin-top: 5px;
}

.Company1-second p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
}

/* Company Titles */
.TBC-second,
.LIBERTY-second,
.ORIS-second,
.TERA-second {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
}

/* Item List */
.ItemList-second {
    position: relative;
    border-radius: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    column-gap: 50px;
    cursor: pointer;
    color: rgba(60, 60, 67, 0.6);
    width: 100%;
    max-width: 35rem;
    transform: translateX(2.2rem) translateY(-1.3rem);
    /* display:none; */
}

.Title1-second {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
}

.company-second {
    color: rgba(60, 60, 67, 0.6);
    line-height: 20px;
    font-weight: 600;
}

.Icon-second {
    margin-left: 1.5rem;
}

.Icon1-second {
    margin-left: 2.7rem;
}

.Icon2-second {
    margin-left: 4rem;
}

.Icon3-second {
    margin-left: 3rem;
}

.Icon5-second {
    margin-left: 1rem;
}

hr.Active-second {
    position: absolute;
    bottom: 0;
    transition: left 0.3s ease, width 0.3s ease;
    border: none;
    background-color: rgba(101, 106, 249, 1);
    height: 1px;
}

.pointer-cursor-second {
    cursor: pointer;
}

/* RESPONSIVE */

@media (max-width:775px) {
    .search-second {
        width: 100%;
        max-width: 30rem;
    }
}

@media (max-width:730px) {
    .startingTitle-second {
        transform: translateY(-1.5rem);
    }

    .SearchBar-second {
        transform: translateY(-4rem);
    }

    .CompanyContainer-second {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
        width: 100%;
        max-width: 20rem;
        row-gap: 1rem;
        transform: translateX(1rem) translateY(-3rem);
        /* display:none; */
    }

    .Company1-second {
        max-width: 9rem
    }

    .ItemList-second {
        max-width: 18rem;
        transform: translateY(0rem) translateX(2rem);
        display: grid;
        grid-template-columns: repeat(2, 100px);
    }
}

@media (max-width:794px) {
    .Main-ContainerMainSearching-second {
        overflow: hidden;
    }
}

@media (max-width:500px) {
    .CompanyContainer-second {
        display: flex;
        width: 100%;
        max-width: 23rem;
        column-gap: 1.7rem;
        overflow-x: auto;
        scrollbar-width: thin;
    }

    .Company1-second {
        width: 7rem;
    }

    .ItemList-second {
        display: flex;
        width: 100%;
        max-width: 20rem;
        overflow-x: auto;
        column-gap: 3rem;
        transform: translateY(-3rem) translateX(1.5rem);
    }

    .Title1-second {
        width: 6rem;
    }

    .Active-second {
        background-color: blue;
    }


}