.BoxContainer {
  width: 100%;
  background-color: #f7f9fc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar2 {
  background-color: rgba(101, 106, 249, 1);
  width: 100%;
  height: 100px;

}

.navbarContainer {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  width: 100%;
}

.Same {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: white;
  font-weight: 500;
}

.Same:hover {
  cursor: pointer;
}

.returnText {
  display:flex;
  align-items:center;
  justify-content:flex-start;
  margin-left: 5rem;
}

.Hotline {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.PrivatePage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PrivatePageIcon {
  transform: translateX(-5px);
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  z-index: 1001;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbarContainer {
    grid-template-columns: 1fr auto auto;
  }

  .returnText {
    justify-self: start;
  }

  .Hotline {
    display: none;
  }

  .PrivatePage {
    justify-self: end;
  }
}

@media (max-width: 480px) {
  .navbarContainer {
    grid-template-columns: 1fr auto;
  }

  .returnText {
    justify-self: start;
  }

  .Hotline {
    display: none;
  }

  .PrivatePage {
    justify-self: end;
  }

  .Same {
    font-size: 14px;
  }
}
@media(max-width:442px){
  .Same{
    width:10rem;
  }
  .PrivatePage{
    transform:translateX(-2rem);
  }
}

@media(max-width:425px){
     .Same{
      transform:translateX(-1rem);
     }
     .returnText{
      width:10rem;
     }
     .PrivatePage{
      width:12rem;
     }
     .PrivatePage{
      transform:translateX(0rem);
    }
}




















@media (max-width:384px){
  .PrivatePage{
    transform:translateX(-1rem);
  }
  .Same{
    transform:translateX(-2rem);
  }
}
@media(max-width:346px){
  .PrivatePage{
    transform:translateX(-1.5rem);
  }
}

@media(max-width:331px){
  .Same{
    transform:translateX(-3.5rem);
  }
}