.MainContainer-HeaderNews {
    width: 100%;
    max-width: 85rem;
    border-radius: 15px;
    margin: auto;
    background-color: white;
    overflow: visible;
    transform: translateX(1rem);
    padding: 2.5rem;
}

/* გავაკეთებთ უკვე ყველა ელემენტს გრიდს */
.AllContent-HeaderNews {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;

}

.CompanyNews-HeaderNews {
    margin-left: 2.5rem;
}

.CompanyNews-HeaderNews .Title-HeaderNews {
    color: rgba(101, 106, 249, 1);
    font-weight: 600;
}

.CompanyNews-HeaderNews .PartTitle-HeaderNews {
    color: #000;
    font-weight: 600;
}



.CompanyNews-HeaderNews {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
}

/* სორტირება */
.SortingPositional {
    display: flex;
    justify-content: flex-end;
    transform: translateX(-3.5rem);
}

.Sorting-HeaderNews {
    border: none;
    width: 100%;
    max-width: 10rem;
    height: 40px;
    border-radius: 15px;
    background-color: white;
    color: #000;
    text-indent: 10px;
    border: 2px solid #E5E5E5;
    transform: translateY(-2rem);
}

.Sorting-HeaderNews:focus {
    outline: none;
}


.MainNews-HeaderNews {
    width: 100%;
    max-width: 15rem;
    height: 16.5rem;
    background-color: rgba(247, 249, 252, 1);
    margin-left: 2rem;
    border-radius: 15px;
    cursor: pointer;
}

/* Main News Title */

.MainNews-HeaderNews .NewsTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 1);
    width: 100%;
    /* შესაცვლელი */
    max-width: auto;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 600;
}


.MainNews-HeaderNews .fonticon {
    position: relative;
    margin-left: 0.5rem;
}

.Media-HeaderNews {
    display: flex;
    width: 100%;
    max-width: 8rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    transform: translateX(1.1rem);
}

.TimeAndDate {
    display: flex;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    color: rgba(146, 152, 166, 1);
    transform: translateY(0rem);
}

.MainNews-HeaderNews .Full2 {
    text-align: end;
    font-weight: 500;
    color: rgba(101, 106, 249, 1);
    margin-right: 1rem;
    transform: translateY(-2rem);
}


.imigi {
    width: 100%;
    max-width: 15rem;
    height: 8.2rem;
    border-radius: 15px 15px 0px 0px;
    z-index: 1;
}

.More-HeaderNews .btn-HeaderNews {
    border: none;
    border-radius: 15px;
    width: 100%;
    max-width: 8.5rem;
    height: 2.3rem;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    background-color: rgba(101, 106, 249, 0.15);
    color: rgba(101, 106, 249, 1);
    cursor: pointer;
    font-weight: bold;
}

.btn-HeaderNews:focus {
    outline: none;
}


/* Advertising */

.advertising {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 13rem;
    height: 2.2rem;
    border: 1px, 1px, 0px, 1px;
    border-radius: 15px 15px 0px 0px;
    background-color: var(--lightblue);
    margin-top: -2.2rem;
    -webkit-transform: translateX(-4rem);
    -ms-transform: translateX(-4rem);
    transform: translateX(-4rem);
}

.advertising .bmge {
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
}

@media (max-width:663px) {
    .Sorting-HeaderNews {
        transform: translateY(-5rem);
    }

    .CompanyNews-HeaderNews {
        transform: translateY(1rem);
    }

}

@media (max-width:604px) {
    .AllContent-HeaderNews {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .CompanyNews-HeaderNews {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}

@media (max-width:424px) {
    .CompanyNews-HeaderNews {
        font-size: 14px;
    }

    .Sorting-HeaderNews {
        transform: translateY(-4.5rem);
    }
}

@media (max-width:385px) {
    .CompanyNews-HeaderNews {
        transform: translateY(2rem) translateX(1rem);
    }
}

@media (max-width:370px) {
    .AllContent-HeaderNews {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width:343px) {
    .AllContent-HeaderNews {
        transform: translateX(-1rem);
    }
}

@media (max-width:319px) {
    .AllContent-HeaderNews {
        transform: translateX(-1rem);
    }
}


@media (max-width:376px) {
    .Sorting-HeaderNews {
        transform: translateX(-2rem) translateY(-5rem);
    }
}