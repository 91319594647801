.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* High z-index to cover other content */
}

.overlay-content {
  /* background-color: white; */
  padding: 2rem;
  border-radius: 10px;
}
