.Recomendation-Container {
    width:100%;
    max-width: 38rem;
    height:26.5rem;
    max-height: 26.5rem;
    border-radius: 15px;
    background-color: #FFFF;
    color: black;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgb(214, 214, 214) transparent; 
    transform:translateY(-0.3rem);
}


.Recomendation-Context {
    display: grid;
    /* grid-template-columns: repeat(1, 550px); */
    grid-template-columns:repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: 140px repeat(auto, 100px);
    margin-left: 2rem;
}


.Recomendation-Context > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}

/* პირველი დიდი მწკრივის ბოლოს ნანახის სათაური */
.RecomendationMain-Title {
    position: sticky;
    top:0;
    background-color: white; 
    z-index: 1000; 
    height:4rem;
}

.RecomendationMain-Title .RecomendationTitle{
    color: #000;
    font-weight: 500;
    cursor: copy;
    width: 565px;
    height: 70px;
}

.Recomendation-MainRow .Recomendation-BOG2 {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    cursor: pointer;
    -webkit-transform:translateY(1rem);
        -ms-transform:translateY(1rem);
            transform:translateY(1rem);
    z-index:0;
}

.Recomendation-MainRow .Recomendation-Bog {
    color: #000;
    font-weight: 500;
    margin-left: 3rem;
    -webkit-transform: translateY(-2.3rem);
        -ms-transform: translateY(-2.3rem);
            transform: translateY(-2.3rem);
    z-index: 0;
}

.Recomendation-MainRow .CompereItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    color: #656AF9;
    font-weight: lighter;
    margin-top: -6rem;
    -webkit-transform: translateX(1rem);
        -ms-transform: translateX(1rem);
            transform: translateX(1rem);
    cursor: pointer;
    z-index: 0;
}

.Recomendation-MainRow .SaveItem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-transform: translateX(0.2rem);
        -ms-transform: translateX(0.2rem);
            transform: translateX(0.2rem);
    margin-top: -0.5rem;
    color: #656AF9;
    font-weight: 500;
    cursor: pointer;
    z-index: 0;
}

/* Rest of your existing CSS for OrdinaryRow */
.Recomendation-OrdinaryRow {
    margin-top: 1rem;
    z-index:0;
}

.Recomendation-OrdinaryRow .Recomendation-BOG {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    cursor: pointer;
    -webkit-transform: translateY(0.5rem);
        -ms-transform: translateY(0.5rem);
            transform: translateY(0.5rem);
}

.Recomendation-OrdinaryRow .Recomendation-Bog {
    color: #000;
    font-weight: 500;
    margin-left: 3rem;
    -webkit-transform: translateY(-3rem);
        -ms-transform: translateY(-3rem);
            transform: translateY(-3rem);
}

.Recomendation-OrdinaryRow .CompereItemForBank{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    color: #656AF9;
    font-weight: lighter;
    margin-top: -6.5rem;
    -webkit-transform: translateX(1rem);
        -ms-transform: translateX(1rem);
            transform: translateX(1rem);
}

.Recomendation-OrdinaryRow .CompereItem:hover {
    cursor: pointer;
}

.Recomendation-OrdinaryRow .SaveItemForBank{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-transform: translateX(0.2rem);
        -ms-transform: translateX(0.2rem);
            transform: translateX(0.2rem);
    margin-top: -0.5rem;
    color: #656AF9;
    font-weight: 500;
}

.Recomendation-OrdinaryRow .SaveItemForBank:hover{
    cursor: pointer;
}

.recommendation-border-color {
    border: none;
    background-color: #D9DBE9;
    height: 1px;
}
.recommendation-border-color2 {
    border: none;
    background-color: #D9DBE9;
    height: 1px;
}


.anri{
    transform:translateY(0.2rem) translateX(3px);
}



/* RESPONSIVE */

@media (max-width:440px){
    .RecomendationMain-Title{
        display:flex;
        width:50rem;
        justify-content:center;
    }.recommendation-border-color {
        border: none;
        background-color: #D9DBE9;
        height: 1px;
    }
    
    .Recomendation-MainRow{
        transform:translateY(-1rem)translateX(2.5rem);
    }
    .Recomendation-MainRow .Recomendation-BOG2{
        transform:translateY(0.7rem) translateX(0rem);
        
    }
    .CompereItem{
        transform:translateX(-15rem);
    }
    .recommendation-border-color2{
        transform:translateX(-3.5rem) translateY(-2rem);
    }
    .Recomendation-MainRow .together{
        display:flex;
        transform:translateY(-2rem);
        
    }
    .Recomendation-MainRow .together .CompereItem{
        transform:translateY(5.5rem)translateX(1rem);
        height:fit-content;
    }   
}

/* all row */

@media (max-width:440px){
    .recommendation-border-color {
        border: none;
        background-color: #D9DBE9;
        height: 1px;
    }
    
    .Recomendation-OrdinaryRow{
        transform:translateY(-1rem)translateX(2.5rem);
    }
    .Recomendation-OrdinaryRow .Recomendation-BOG{
        transform:translateY(0.1rem) translateX(0rem);
        
    }
    .CompereItemForBank{
        transform:translateX(-15rem);
    }
    .recommendation-border-color{
        transform:translateX(-3.5rem) translateY(-2rem);
    }
    .Recomendation-OrdinaryRow .together2{
        display:flex;
        transform:translateY(-2.5rem);
    }
    .Recomendation-OrdinaryRow .together2 .CompereItemForBank{
        transform:translateY(5.9rem)translateX(1rem);
        height:fit-content;
    }   
}

@media (max-width:380px){
   .RecomendationMain-Title{
    /* transform:translateX(-2rem); */
   }
}