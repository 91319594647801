.Global-wrapper{
    display:flex;
    align-items:center;
    justify-content:center;
    row-gap:4rem;
    column-gap:7.5rem;
    transform:translateY(-8rem);
}

@media (max-width:944px){
    .Global-wrapper{
        display:flex;
        flex-direction:column;
    }
}