.Main-ContainerCompere{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; /* Change to column to stack content */
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; /* Center align the content */
    width: 80rem;
    height: auto; /* Make height auto to accommodate all children */
    border-radius: 15px;
    background-color: #FFFFFF;
    margin: 9rem auto;
    padding-bottom: 2rem; /* Add padding to bottom for spacing */
}

/*Context */
.First-Container-Compere{
    display:-ms-grid;
    display:grid;
    -ms-grid-columns:(426px)[3];
    grid-template-columns:repeat(3, 426px);
    -ms-grid-rows:(70px)[3];
    grid-template-rows:repeat(3, 70px);
}
.First-Container-Compere > *:nth-child(1){
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
.First-Container-Compere > *:nth-child(2){
    -ms-grid-row: 1;
    -ms-grid-column: 2;
}
.First-Container-Compere > *:nth-child(3){
    -ms-grid-row: 1;
    -ms-grid-column: 3;
}
.First-Container-Compere > *:nth-child(4){
    -ms-grid-row: 2;
    -ms-grid-column: 1;
}
.First-Container-Compere > *:nth-child(5){
    -ms-grid-row: 2;
    -ms-grid-column: 2;
}
.First-Container-Compere > *:nth-child(6){
    -ms-grid-row: 2;
    -ms-grid-column: 3;
}
.First-Container-Compere > *:nth-child(7){
    -ms-grid-row: 3;
    -ms-grid-column: 1;
}
.First-Container-Compere > *:nth-child(8){
    -ms-grid-row: 3;
    -ms-grid-column: 2;
}
.First-Container-Compere > *:nth-child(9){
    -ms-grid-row: 3;
    -ms-grid-column: 3;
}

.Compere{
    margin-left:2rem;
    margin-top:0.5rem;
    -ms-grid-column:1;
    -ms-grid-column-span:2;
    grid-column:1/3;
}
.CompereTitle{
    color:rgba(0,0,0,1);
    font-weight:600;
    cursor: copy;
}
/* Font Icon */

.Font-Icon-X{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    -webkit-box-pack:end;
        -ms-flex-pack:end;
            justify-content:flex-end;
    margin-right:1.5rem;
    margin-top:0.3rem;
    -ms-grid-column:3;
    grid-column:3/3;
    cursor:pointer;
}
.Icon{
    font-size:20px;
    color:#000000;
}

/* სერჩის ღილაკი */
/* .Compeare-SearchInput{
    margin-left:1.5rem;
    margin-top:-0.5rem;
} */
/* .Compeare-SearchInput {
    position: relative;
    display: flex;
    align-items: center;
} */
.Compeare-SearchInput {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 20px; /* Adjust this value as needed */
}
.CompereSearch::-webkit-input-placeholder{
    background-color:#F7F9FC;
    color:#9298A6;
    font-weight:500;
    letter-spacing:1px;
    font-size:14px;
}
.CompereSearch::-moz-placeholder{
    background-color:#F7F9FC;
    color:#9298A6;
    font-weight:500;
    letter-spacing:1px;
    font-size:14px;
}
.CompereSearch:-ms-input-placeholder{
    background-color:#F7F9FC;
    color:#9298A6;
    font-weight:500;
    letter-spacing:1px;
    font-size:14px;
}
.CompereSearch::-ms-input-placeholder{
    background-color:#F7F9FC;
    color:#9298A6;
    font-weight:500;
    letter-spacing:1px;
    font-size:14px;
}
.CompereSearch::placeholder{
    background-color:#F7F9FC;
    color:#9298A6;
    font-weight:500;
    letter-spacing:1px;
    font-size:14px;
}
/* .CompereSearch{
    width:25rem;
    height:40px;
    border-radius:15px;
    border:none;
    outline:none;
    background-color:#F7F9FC;
    color:#000000;
    text-indent:2.5rem;
} */
.CompereSearch {
    width: calc(100% - 40px); /* Adjust the width to accommodate the padding */
    padding: 12px; /* Increase padding for a larger background */
    font-size: 16px;
    border: none; /* Remove the border */
    border-radius: 10px; /* Increase border radius for rounded corners */
    background-color: #F7F9FC; /* Set background color */
    color: #000000; /* Set text color */
    margin-left: 20px; /* Adjust this value as needed */
}

.suggestions-dropdown {
    position: absolute;
    top: 100%;
    left: 40;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-top: none;
    z-index: 1000;
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 150px; /* Set maximum height for the dropdown */
    overflow-y: auto; /* Enable vertical scrollbar when content exceeds max height */
    width: calc(100% - 40px); /* Adjust the width if the input margin is modified */
}



.suggestions-dropdown li {
    padding: 8px 12px;
    cursor: pointer;
    -webkit-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
}

.suggestions-dropdown li:hover {
    background-color: #f7f7f7;
}

.loading {
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}

.CompereSearch {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    /* border-radius: 4px; */
}

/* Limit Warning */
.Limit-Warning{
    -ms-grid-column:1;
    grid-column:1/1;
    -ms-grid-row:3;
    grid-row:3;
    width:450px;
    margin-left:2rem;
    margin-top:-2rem;
}
.Limit{
    color:#BC0022;
    font-weight:400;
    cursor:copy;
}

/* Choose year for sorting */
.ChooseYear-Compere{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    text-align:center;
    -ms-grid-column:3;
    grid-column:3/3;
    -ms-grid-row:2;
    grid-row:2/2;
    width:10rem;
    height:2.5rem;
    border-radius:15px;
    outline:none;
    border:2px solid #E5E5E5;
    background-color:#FFFFFF;
    color:rgba(0,0,0,1);
    font-weight:500;
    margin-left:15.5rem;
    margin-top:0.7rem;
}
.ChooseYear-Compere:focus{
    outline:none;
}

/*Another chapter, making some table container for compare*/

/* This is just position */
/* .TableContainer-Compere{
    width:75rem;
    height:40rem;
    background-color:#D9DBE9;
    border-radius:15px;
    border:1px solid gray;
    
    margin-left:-77.5rem;
    margin-top:13rem;
} */

.TableContainer-Compere{
    width: 75rem;
    height: 40rem;
    background-color: #D9DBE9;
    border-radius: 15px;
    border: 1px solid gray;
    margin-top: 2rem; /* Adjust margin-top to ensure it's below the previous elements */
}


.Compeare-SearchInput input {
    border: none; /* Remove the border */
    outline: none; /* Remove the outline */
    /* Add any other styles as needed */
}

.Compeare-SearchInput input:focus {
    border: none; /* Remove border on focus */
    outline: none; /* Remove outline on focus */
}