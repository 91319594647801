.BoxContainerForSimilar {
    width: 100%;
    max-width: 81rem;
    height: fit-content;
    padding: 2rem;
    border-radius: 15px;
    background-color: #FFFF;
    margin: 5rem auto;
    overflow: visible;
    transform: translateX(-1rem);

}

.AllContent {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 2rem;
    transform: translateY(-1rem) translateX(1rem);
}

.SimCompany {
    display: flex;
    align-items: center;
    cursor: copy;
    transform: translateX(0.5rem) translateY(-1rem);
}

.SimilarCompany-Tit {
    color: #000;
    font-weight: 600;
}

.SeeMoreSimilar {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    transform: translateY(-5rem) translateX(-1rem);
}

.SeeSimilar {
    color: #656AF9;
    font-weight: bold;
}


.BOGSimilar {
    margin-left: -1rem;
    border-radius: 15px;
    width: 100%;
    max-width: 300px;
    height: 104px;
    background-color: #F7F9FC;
    transform: translateY(-1.5rem);
}

.BuildingInCirlce {
    width: 36px;
    height: 36px;
    margin-left: 1rem;
    transform: translateY(1.8rem);
    border-radius: 100%;
}

.BogTextSimilar {
    color: rgba(0, 0, 0, 1);
    line-height: 20px;
    font-weight: 500;
    margin-left: 3.8rem;
    -webkit-transform: translateY(-1.3rem);
    -ms-transform: translateY(-1.3rem);
    transform: translateY(-1.3rem);
}


.IconDownSimilar {
    margin-left: 15.5rem;
    margin-top: -4.7rem;
    color: rgba(146, 152, 166, 1);
}

.SeeBog {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-transform: translateX(-0.7rem);
    transform: translateX(-0.7rem) translateY(-6rem);
    color: #9298A6;
    font-weight: lighter;
    cursor: pointer;
    font-size: 13px;
}

@media (max-width: 573px) {
    .AllContent {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .SimCompany {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 436px) {
    .SimCompany {
        transform: translateX(2rem);
    }

    .AllContent {
        transform: translateX(1rem) translateY(-1rem);
        margin-top: 2rem;
    }
}

@media (max-width: 769px) {
    .SimCompany {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .AllContent {
        transform: translateX(2.5rem) translateY(-1rem);
    }
}

@media (max-width: 821px) {
    .AllContent {
        transform: translateX(2.5rem) translateY(-1rem);
    }
}



@media (max-width: 390px) {
    .SeeMoreSimilar {
        transform: translateY(-5rem);
    }
}

@media (max-width:1194px) {
    .SeeBog {
        transform: translateY(-7rem) translateX(-1rem);
    }
}

@media (max-width:1110px) {
    .SeeBog {
        transform: translateY(-6rem) translateX(-1rem);
    }
}

@media (max-width:940px) {
    .SeeBog {
        transform: translateY(-7rem) translateX(-1rem);
    }
}

@media (max-width:840px) {
    .SeeBog {
        transform: translateY(-6rem) translateX(-1rem);
    }
}

@media (max-width:630px) {
    .SeeBog {
        transform: translateY(-7rem) translateX(-1rem);
    }
}

@media (max-width:571px) {
    .SeeBog {
        transform: translateY(-6rem) translateX(-1rem);
    }
}

@media (max-width:560px) {
    .SimCompany {
        transform: translateY(1rem) translateX(3rem);
    }
}

@media (max-width:421px) {
    .AllContent {
        transform: translateX(1rem);
    }

    .SimCompany {
        transform: translateX(1rem) translateY(3rem);
    }
}