.MainContainer-DIff {
    position: relative;
    width: 85rem;
    max-width: 100%;
    height: fit-content;
    border-radius: 15px;
    margin: auto;
    background-color: var(--white);
    padding: 1rem;
    margin-top: 4rem;
}

.AllContent-Different {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    row-gap: 2rem;
    scrollbar-width: thin;
}

/* Advertising */
.PoweredBy-bmg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    width: 13rem;
    max-width: 100%;
    height: 2.2rem;
    -webkit-transform: translateY(-2.1rem) translateX(-5rem);
    -ms-transform: translateY(-2.1rem) translateX(-5rem);
    transform: translateY(-2.1rem) translateX(-5rem);
    border-radius: 15px 15px 0px 0px;
    background-color: #5CD1F4;
}

.PoweredBy-bmg button {
    background-color: transparent;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.PoweredBy-bmg button:focus {
    outline: none;
}

/* Company title */
.CompanyNewsOriginal {
    display: flex;
    align-items: center;
    height: 6rem;
    width: 11rem;
    max-height: 100%;
    transform: translateX(2.2rem) translateY(-1rem);
}

.CompanyNewsOriginal .Title {
    width: 180px;
    color: #000;
    font-weight: 600;
}

/* Sorting Button position */

.Sorting {
    -ms-grid-column: 4;
    grid-column: 4/4;
    -ms-grid-row: 1;
    grid-row: 1;
    margin-top: 2.5rem;
    margin-left: 2rem;
}

.Sorting .SortingYear {
    border-radius: 15px;
    border: none;
    width: 10.1875rem;
    height: 2.5rem;
    background-color: white;
    border: 1px solid rgba(229, 229, 229, 1);
    text-indent: 2px;
    cursor: pointer;
}


.MainNews-ForNews {
    width: 15rem;
    max-width: 100%;
    height: fit-content;
    background-color: rgba(247, 249, 252, 1);
    margin-left: 2rem;
    -webkit-transform: translateY(-2rem);
    -ms-transform: translateY(-2rem);
    transform: translateY(-1rem);
    border-radius: 0px 0px 10px 10px;
    cursor: pointer;
    border-radius: 15px;
}

/* ფოტო არაა ბოლლომდე გახსნილი */

/* Main News Title */

.MainNews-ForNews .NewsTitle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: rgba(0, 0, 0, 1);
    width: 13rem;
    max-width: 100%;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 600;
}

.MainNews-ForNews .Full {
    text-align: end;
    margin-top: -2rem;
    font-weight: 500;
    color: rgba(101, 106, 249, 1);
    margin-right: 1rem;
    transform: translateY(-2px);
}


.Media {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 6rem;
    max-width: 8rem;
    margin-left: 1.15rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    margin-top: 1.5rem;
    transform: translateX(-1.4rem);
}

.TimeAndDate {
    width: 5.5625rem;
    height: 0.6rem;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    color: rgba(146, 152, 166, 1);
    margin-left: 1.1rem;
    margin-top: -1.3rem;
}


.ContainerImage {
    width: 15rem;
    max-width: 100%;
    height: 8.2rem;
    border-radius: 15px 15px 0px 0px;
    z-index: 1;
}

/* Second Button Location(See More) */
.More {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: end;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-grid-column: 2;
    grid-column: 2;
    grid-row: auto;
    -webkit-transform: translateX(11rem);
    -ms-transform: translateX(11rem);
    transform: translateX(11rem);
    margin-top: 1rem;
}

.More .btn {
    border: none;
    border-radius: 15px;
    width: 8.5rem;
    height: 2.3rem;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    background-color: rgba(101, 106, 249, 0.15);
    color: rgba(101, 106, 249, 1);
    cursor: pointer;
    font-weight: bold;
}


/* Advertising */

@media (max-width:587px) {
    .AllContent-Different {
        transform: translateX(7.5rem);
    }

    .CompanyNewsOriginal {
        transform: translateX(14rem) translateY(-1rem);
    }
}

@media (max-width:463px) {
    .AllContent-Different {
        transform: translateX(4rem);
    }

    .CompanyNewsOriginal {
        transform: translateX(10rem) translateY(-1rem);
    }
}

@media (max-width:383px) {
    .AllContent-Different {
        transform: translateX(1.3rem);
    }

    .CompanyNewsOriginal {
        transform: translateX(7rem) translateY(-1rem);
    }
}